import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { DeleteItemModal } from '../folder/DeleteItemModal'
import { Arrow } from '../icons/Arrow'
import { TrashIcon } from '../icons/TrashIcon'
import { baseProps, Item } from './ChildrenSortingList'

type props = {
  item: Item
} & baseProps

export const ChildItem: FC<props> = ({
  item: { label, position, uuid, linkPrefix },
  reorder,
  onDelete,
}) => {
  const moveUp = () => reorder(position, position - 1)
  const moveDown = () => reorder(position, position + 1)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  return (
    <>
      <div
        data-testid='ChildItem'
        className='flex flex-nowrap items-center w-full rounded-md border p-2'>
        <Arrow className='clickable m-1 transform rotate-90' onClick={moveUp} />
        <Arrow
          className='clickable m-1 transform -rotate-90'
          onClick={moveDown}
        />
        <h4 className='mx-2'>{label}</h4>
        <TrashIcon
          onClick={() => setShowDeleteDialog(true)}
          className='clickable m-1'
        />
        <Link className='ml-auto' to={`${linkPrefix}/${uuid}`}>
          <Arrow className='clickable m-1 transform rotate-180' />
        </Link>
      </div>
      <DeleteItemModal
        open={showDeleteDialog}
        remove={() => onDelete(uuid)}
        setOpen={setShowDeleteDialog}
      />
    </>
  )
}
