import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { GET_BLOCK } from '../backbone/queries'
import { Block, QueryBlockArgs } from '../backbone/types'
import { patchUuids } from '../backbone/utils'
import { useEditorActions } from '../hooks/useEditorActions'
import { useEditorRouteParams } from '../hooks/useEditorRouteParams'
import { useLoadTemplateBlocks } from '../hooks/useLoadTemplateBlocks'
import { MaterialButton } from '../shared/MaterialButton'
import { MaterialSelect } from '../shared/MaterialSelect'

export const TemplateBlockSelector = () => {
  const { data } = useLoadTemplateBlocks()
  const { form } = useEditorRouteParams()
  const [uuid, setUuid] = useState('')
  const history = useHistory()
  const { submit, loading, template } = useAddTemplateBlock(uuid)

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    submit()
    history.push(`/editor/${form}/${template?.uuid}`)
  }
  return (
    <form onSubmit={onSubmit} className='pl-8 py-4 flex items-center space-x-1'>
      <MaterialSelect
        value={uuid}
        onChange={(e) => setUuid(e.currentTarget.value)}>
        <option>Vorlage auswählen</option>
        {data?.allTemplates.map((t, i) => (
          <option value={t.uuid} key={i}>
            {t.label}
          </option>
        ))}
      </MaterialSelect>

      <MaterialButton
        className='disabled:opacity-50'
        disabled={loading || !template}
        type='submit'>
        speichern
      </MaterialButton>
    </form>
  )
}

export const useAddTemplateBlock = (uuid: string) => {
  const { saveBlock } = useEditorActions()
  const { data, loading } = useQuery<{ block: Block }, QueryBlockArgs>(
    GET_BLOCK,
    {
      variables: { uuid },
    }
  )

  const template = patchUuids(data?.block)

  return {
    submit: () => {
      if (!!template) saveBlock(template)
    },
    template,
    loading,
  }
}
