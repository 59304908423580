import { FC } from 'react'
import { Statement } from '../backbone/types'
import { TrashIcon } from '../icons/TrashIcon'
import { MaterialButton } from '../shared/MaterialButton'
import { ConditionStatementForm } from './ConditionStatementForm'

export const ConditionConfigurator: FC<{
  condition: Statement[]
  setCondition: (c: Statement[]) => void
}> = ({ condition, setCondition }) => {
  const updateConditionAtIndex = (index: number) => (s: Partial<Statement>) => {
    const nextCondition = condition.map((c, i) =>
      i !== index ? c : { ...c, ...s }
    )
    setCondition(nextCondition)
  }

  const addStatement = () => {
    const newStatement: Statement = {
      target: '',
      operand: '==',
      value: '',
    }
    setCondition([
      ...condition.map((s) => ({ ...s, con: s.con || '&' })),
      newStatement,
    ])
  }

  const removeStatementAtIndex = (index: number) => () =>
    setCondition(
      condition
        .filter((_, i) => i !== index)
        .map((c, i, arr) =>
          i !== arr.length - 1
            ? c
            : { target: c.target, operand: c.operand, value: c.value }
        )
    )

  return (
    <div className='my-8'>
      {condition.map((stmt, i) => (
        <div className='flex items-center gap-2 my-2'>
          <div className='w-1/6'>Bedingung {i + 1}</div>
          <div className='w-full pl-0.5 flex gap-2'>
            <ConditionStatementForm
              {...stmt}
              key={i}
              onChange={updateConditionAtIndex(i)}
            />
          </div>
          <TrashIcon onClick={removeStatementAtIndex(i)} />
        </div>
      ))}
      <MaterialButton onClick={addStatement}>
        + Bedingung hinzufügen
      </MaterialButton>
    </div>
  )
}
