import { FC, SVGProps } from 'react'

export const AlignCenter: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path d='M16 1H0V3H16V1Z' fill='currentColor' />
    <path d='M13 5H3V7H13V5Z' fill='currentColor' />
    <path d='M16 9H0V11H16V9Z' fill='currentColor' />
    <path d='M13 13H3V15H13V13Z' fill='currentColor' />
  </svg>
)
