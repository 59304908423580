import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from '../redux/store'
import { UserButton } from './UserButton'

export const AuthButton = () => {
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)

  return isLoggedIn ? <UserButton /> : <Link to='/login'>Anmelden </Link>
}
