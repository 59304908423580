import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 } from 'uuid'
import { useEditorBlock } from '../hooks/useEditorBlock'
import { useEditorRouteParams } from '../hooks/useEditorRouteParams'
import { MaterialButton } from '../shared/MaterialButton'

export const NewBlockSaveButton: FC<{ uuid: string }> = ({ uuid }) => {
  const { form } = useEditorRouteParams()
  const { setBlock } = useEditorBlock(uuid)
  const history = useHistory()

  const onSave = () => {
    const siteUuid = v4()
    setBlock({
      sites: [
        {
          uuid: siteUuid,
          inputs: [],
          label: 'Neue Seite',
          name: 'new_site',
          position: 0,
        },
      ],
    })
    history.push(`/editor/${form}/${uuid}/${siteUuid}`)
  }

  return (
    <div onClick={onSave}>
      <MaterialButton type='submit'>speichern</MaterialButton>
    </div>
  )
}
