import { FC } from 'react'
import { Option } from '../backbone/types'

export const OptionLabelInput: FC<{
  value: Partial<Option>
  onChange: (v: Partial<Option>) => void
}> = ({ value, onChange }) => {
  return (
    <input
      className='block flex-1 px-3 py-1.5 text-base font-normal text-dark bg-clip-padding border-none outline-none  transition ease-in-out m-0 !ring-0'
      value={value?.label || ''}
      onChange={(e) =>
        onChange({
          label: e.currentTarget.value,
        })
      }
      type='text'
      id='exampleFormControlInput1'
    />
  )
}
