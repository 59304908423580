import { FC } from 'react'
import { Form } from '../backbone/types'
import { useContextMenuControls } from '../backbone/useContextMenuControls'
import { FormContextMenu } from './FormContextMenu'
import { FormItemView } from './FormItemView'

export const FormItem: FC<{ form: Form }> = ({ form }) => {
  const props = useContextMenuControls()
  return (
    <>
      <FormItemView menuProps={props} form={form} />
      <FormContextMenu {...props} {...form} />
    </>
  )
}
