import React, { FC } from 'react'
import { MaterialInput } from '../shared/MaterialInput'
import { useEditorBlock } from '../hooks/useEditorBlock'

interface props {
  uuid: string
  postfix?: string
}

export const BlockNameInput: FC<props> = ({ uuid, postfix = '(Vorlage)' }) => {
  const { block, setBlock } = useEditorBlock(uuid)
  const onChange = (label: string) => setBlock({ label })

  return (
    <MaterialInput
      label='Titel:'
      value={block.isTemplate ? `${block.label} ${postfix}` : block.label}
      onChange={onChange}
    />
  )
}
