import {
  convertFromRaw,
  convertToRaw,
  Editor as Draft,
  EditorState as DraftState,
  RichUtils,
} from 'draft-js'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'
import React, { FC, useEffect, useState } from 'react'
import { Bold } from '../icons/Bold'
import { Italic } from '../icons/Italic'

interface props {
  value?: string
  onChange: (v: string) => void
  className?: string
}

export const RichTextInput: FC<props> = ({ value, onChange, className }) => {
  const [draftState, setDraftState] = useState(() =>
    !!value
      ? DraftState.createWithContent(convertFromRaw(markdownToDraft(value)))
      : DraftState.createEmpty()
  )
  const toggleInlineStyle = (inlineStyle: string) => () =>
    setDraftState(RichUtils.toggleInlineStyle(draftState, inlineStyle))
  const buttons = [
    { Icon: Bold, command: 'BOLD' },
    { Icon: Italic, command: 'ITALIC' },
  ]

  const content = draftToMarkdown(convertToRaw(draftState.getCurrentContent()))

  //  eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange(content), [content])

  return (
    <div className={'shadow-md w-full rounded overflow-hidden ' + className}>
      <div className='flex space-x-8 items-center bg-gray-100 p-3 shadow'>
        {buttons.map(({ Icon, command }, index) => (
          <Icon
            className='clickable w-4 '
            onClick={toggleInlineStyle(command)}
            key={index}
          />
        ))}
      </div>
      <div className='p-3 max-h-40 overflow-auto'>
        <Draft
          stripPastedStyles={true}
          customStyleMap={{}}
          editorState={draftState}
          onChange={setDraftState}
        />
      </div>
    </div>
  )
}
