import { useMutation } from '@apollo/client'
import { CREATE_FOLDER } from '../backbone/queries'
import { MutationCreateFolderArgs } from '../backbone/types'

export const useCreateFolder = () => {
  const [mutate, request] = useMutation<any, MutationCreateFolderArgs>(
    CREATE_FOLDER,
    { refetchQueries: 'active' }
  )

  return {
    createNewFolder: () =>
      mutate({
        variables: { input: { label: 'Neuer Ordner', name: 'new_folder' } },
      }),
    request,
  }
}
