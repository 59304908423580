import React, { FC } from 'react'
import { ItemViewToggle } from '../shared/ItemViewToggle'
import { AddFolderButton } from './AddFolderButton'
import { FolderItemSortingSelector } from './FolderItemSortingSelector'

export const FolderViewHeader: FC = () => (
  <>
    <h1 className='text-2xl font-bold text-black mb-8'>Formulare</h1>
    <div className='flex gap-4 my-2 w-full justify-start'>
      <FolderItemSortingSelector />
      <ItemViewToggle />
      <AddFolderButton className='ml-auto' />
    </div>
  </>
)
