import { FC, MouseEvent, useState } from 'react'
import { useRootFolderItems } from '../backbone/useRootFolderItems'
import { useMoveFormMutation } from '../hooks/useMoveFormMutation'
import { useSnackbar } from '../hooks/useSnackbar'
import { InfoIcon } from '../icons/InfoIcon'
import { MaterialSelect } from '../shared/MaterialSelect'
import { Modal } from '../shared/Modal'

interface props {
  menu: {
    open: boolean
    setOpen: (v: boolean) => void
  }
  formUuid: string
  formLabel: string
}

export const MoveFormToFolderModal: FC<props> = ({
  menu,
  formUuid,
  formLabel,
}) => {
  const { folders } = useRootFolderItems()
  const { moveForm } = useMoveFormMutation()
  const [folderUuid, setFolderUuid] = useState('')
  const folderLabel = folders.find((f) => f.uuid === folderUuid)?.label
  const { show: showSnackbar } = useSnackbar({
    title: <InfoIcon />,
    text: `${formLabel} wurde erfolgreich in ${folderLabel} verschoben`,
  })
  const submit = (e: MouseEvent) => {
    e.preventDefault()
    if (!!folderUuid)
      moveForm({
        variables: { formUuid, folderUuid },
      }).then(() => {
        menu.setOpen(false)
        showSnackbar()
      })
  }
  return (
    <Modal
      {...menu}
      submit={{ label: 'Verschieben', action: submit }}
      buttons={[{ label: 'Abbrechen', action: () => menu.setOpen(false) }]}
      title='Formular verschieben'>
      <div className='flex flex-col flex-1 justify-evenly'>
        <h3 className='mb-5'>Dokument: {formLabel}</h3>
        <MaterialSelect
          onChange={(e) => setFolderUuid(e.currentTarget.value)}
          className='!w-full mb-5'>
          <option>Zielordner auswählen</option>
          {folders.map((f) => (
            <option value={f.uuid} key={f.uuid}>
              {f.label}
            </option>
          ))}
        </MaterialSelect>
      </div>
    </Modal>
  )
}
