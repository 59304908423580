import { FC } from 'react'
import { InputType } from '../backbone/types'
import { FieldTypes } from '../backbone/utils'
import { useEditorSite } from '../hooks/useEditorSite'
import { MaterialSelect } from '../shared/MaterialSelect'

interface Props {
  blockUuid: string
  siteUuid: string
}

export const AddFieldSelector: FC<Props> = ({ blockUuid, siteUuid }) => {
  const { addField } = useEditorSite(blockUuid, siteUuid)
  return (
    <MaterialSelect
      value=''
      onChange={(e) => addField?.(e.currentTarget.value as InputType)}>
      <option value=''>Feld hinzufügen</option>
      {FieldTypes.map((f, i) => (
        <option key={i} value={f.type}>
          {f.label}
        </option>
      ))}
    </MaterialSelect>
  )
}
