import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router'
import { RootState } from '../redux/store'

interface props extends RouteProps {
  userHasRight?: string
}

export const ProtectedRoute: FC<props> = ({ userHasRight, ...props }) => {
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)
  const rights = useSelector((state: RootState) => state.auth.rights)
  const isAllowed = !userHasRight || rights.includes(userHasRight)

  return isLoggedIn && isAllowed ? (
    <Route {...props} />
  ) : (
    <Redirect to='/login' />
  )
}
