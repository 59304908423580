import { FC, SVGProps } from 'react'

export const FormIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path d='M20.625 0.9375V7.5H27.1875L20.625 0.9375Z' fill='#119CBB' />
    <path
      d='M18.75 9.375V0H2.8125C2.56386 0 2.3254 0.0987721 2.14959 0.274587C1.97377 0.450403 1.875 0.68886 1.875 0.9375V29.0625C1.875 29.3111 1.97377 29.5496 2.14959 29.7254C2.3254 29.9012 2.56386 30 2.8125 30H27.1875C27.4361 30 27.6746 29.9012 27.8504 29.7254C28.0262 29.5496 28.125 29.3111 28.125 29.0625V9.375H18.75ZM7.96875 7.5H14.5312C14.6556 7.5 14.7748 7.54939 14.8627 7.63729C14.9506 7.7252 15 7.84443 15 7.96875V8.90625C15 9.03057 14.9506 9.1498 14.8627 9.23771C14.7748 9.32561 14.6556 9.375 14.5312 9.375H7.96875C7.84443 9.375 7.7252 9.32561 7.63729 9.23771C7.54939 9.1498 7.5 9.03057 7.5 8.90625V7.96875C7.5 7.84443 7.54939 7.7252 7.63729 7.63729C7.7252 7.54939 7.84443 7.5 7.96875 7.5ZM22.0312 22.5H7.96875C7.84443 22.5 7.7252 22.4506 7.63729 22.3627C7.54939 22.2748 7.5 22.1556 7.5 22.0312V21.0938C7.5 20.9694 7.54939 20.8502 7.63729 20.7623C7.7252 20.6744 7.84443 20.625 7.96875 20.625H22.0312C22.1556 20.625 22.2748 20.6744 22.3627 20.7623C22.4506 20.8502 22.5 20.9694 22.5 21.0938V22.0312C22.5 22.1556 22.4506 22.2748 22.3627 22.3627C22.2748 22.4506 22.1556 22.5 22.0312 22.5ZM22.0312 15.9375H7.96875C7.84443 15.9375 7.7252 15.8881 7.63729 15.8002C7.54939 15.7123 7.5 15.5931 7.5 15.4688V14.5312C7.5 14.4069 7.54939 14.2877 7.63729 14.1998C7.7252 14.1119 7.84443 14.0625 7.96875 14.0625H22.0312C22.1556 14.0625 22.2748 14.1119 22.3627 14.1998C22.4506 14.2877 22.5 14.4069 22.5 14.5312V15.4688C22.5 15.5931 22.4506 15.7123 22.3627 15.8002C22.2748 15.8881 22.1556 15.9375 22.0312 15.9375Z'
      fill='#119CBB'
    />
  </svg>
)
