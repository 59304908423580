import React from 'react'
import { Route } from 'react-router-dom'
import { useIsDirty } from '../hooks/useIsDirty'
import { MaterialButton } from '../shared/MaterialButton'
import { Spinner } from '../shared/Spinner'
import { useSaveForm } from './useSaveForm'

export const EditorSaveButton = () => {
  const {
    saveForm,
    request: { loading },
  } = useSaveForm()
  const { isDirty, resolve } = useIsDirty()

  return (
    <Route path='/editor'>
      <MaterialButton
        className='disabled:opacity-50 flex space-x-1'
        onClick={() => saveForm({ onCompleted: resolve })}>
        {loading && <Spinner />}
        <span>{isDirty ? 'Formular speichern' : 'Gespeichert'}</span>
      </MaterialButton>
    </Route>
  )
}
