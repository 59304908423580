import React, { FC } from 'react'
import { labelToName } from '../backbone/labelToName'
import { Input, Option } from '../backbone/types'
import { FieldTypeInfoTexts } from '../backbone/utils'
import { ConditionConfigurator } from './ConditionConfigurator'
import { FieldConfiguratorFormattedTextInput } from './FieldConfiguratorFormattedTextInput'
import { FieldConfiguratorOptionListInput } from './FieldConfiguratorOptionListInput'
import { FieldConfiguratorTextInput } from './FieldConfiguratorTextInput'

export const FieldConfigurator: FC<{
  input: Input
  isOpen: boolean
  saveInput: (input: Partial<Input>) => void
}> = ({ input, isOpen, saveInput }) => {
  const getReduxConnectionProps = (name: keyof Input) => {
    return {
      name,
      value: input[name]?.toString() || '',
      onChange: (value: any) =>
        name === 'label'
          ? saveInput({ label: value, name: labelToName(value) })
          : saveInput({ [name]: value }),
    }
  }

  return !isOpen ? null : (
    <div className='p-2 w-full mt-6'>
      <div className='flex w-full'>
        <FieldConfiguratorTextInput
          label='Titel'
          showAlign={['InfoField'].includes(input.__typename!)}
          saveInput={saveInput}
          input={input}
        />
      </div>
      {['Select', 'InfoField', 'TextField', 'TextArea'].includes(
        input.__typename!
      ) && (
        <FieldConfiguratorFormattedTextInput
          label='Hinweistext'
          {...getReduxConnectionProps('helpText')}
        />
      )}
      {input.__typename === 'Select' && (
        <FieldConfiguratorOptionListInput
          isBranchingPoint={input.isBranchingPoint}
          options={(input.options ?? []) as Option[]}
          setOptions={(options) => saveInput({ options })}
        />
      )}
      <ConditionConfigurator
        setCondition={(condition) => saveInput({ condition })}
        condition={input.condition}
      />
      {!!input.__typename && (
        <span className='text-sm text-gray-400 pt-2 block '>
          Info: {FieldTypeInfoTexts[input.__typename]}
        </span>
      )}
    </div>
  )
}
