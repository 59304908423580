import React from 'react'
import { CloseIcon } from '../icons/CloseIcon'
import { SearchIcon } from '../icons/SearchIcon'

export const SearchFormInput = ({
  value,
  setValue,
}: {
  value: string
  setValue: (v: string) => void
}) => {
  return (
    <div className='border shadow-md rounded text-gray-500 px-2 flex items-center'>
      <SearchIcon className='fill-current mx-2 inline-block' />
      <input
        type='text'
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        className='border-none p-0 m-0 h-full py-2 !ring-0'
        placeholder='Formulare durchsuchen'
      />
      {value && (
        <CloseIcon
          className={`clickable fill-current w-4 ${
            !value && 'text-transparent'
          }`}
          onClick={() => setValue('')}
        />
      )}
    </div>
  )
}
