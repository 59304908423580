import { FC } from 'react'
import { useUuid } from '../backbone/hooks'

interface props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'onChange'
  > {
  label?: string
  onChange: (v: string) => void
}

export const MaterialInput: FC<props> = ({
  label,
  onChange,
  className,
  ...props
}) => {
  const uuid = useUuid()
  return (
    <div className='flex'>
      <div className='xl:w-96 flex items-center space-x-2'>
        {!!label && (
          <label
            htmlFor={uuid}
            className='form-label inline-block text-gray-700'>
            {label}
          </label>
        )}
        <input
          {...props}
          onChange={(e) => onChange(e.currentTarget.value)}
          type='text'
          className={
            'form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white  bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none shadow-md ' +
            className
          }
          id={uuid}
        />
      </div>
    </div>
  )
}
