import { FC } from 'react'
import { Form } from '../backbone/types'
import { useGridViewToggle } from '../backbone/useGridViewToggle'
import { useSubFolderItems } from '../backbone/useSubFolderItems'
import { useFolderItemSorting } from '../hooks/useFolderItemSorting'
import { FormItem } from './FormItem'

interface props {
  filter: string
}
export const SubFolderItemList: FC<props> = ({ filter }) => {
  const { sortingFunction } = useFolderItemSorting()
  const { forms, loading } = useSubFolderItems()
  const applyFilter = (form: Form) =>
    !filter ||
    (filter === 'drafts' && form.isDraft) ||
    (filter === 'published' && !form.isDraft)

  const { isGridView } = useGridViewToggle()

  if (loading) return null

  return !forms.length ? (
    <div className='text-s text-gray-400 mt-12 w-full flex justify-center'>
      Es sind noch keine Formulare vorhanden. Erstellen Sie Ihr erstes Formular.
    </div>
  ) : (
    <div
      className={`flex flex-wrap 
          ${
            isGridView
              ? 'py-2 grid-cols-2 flex-wrap gap-5'
              : 'py-2 grid-cols-2 flex-row'
          }
        `}>
      {forms
        .filter(applyFilter)
        .slice()
        .sort(sortingFunction)
        .map((form, key) => ({ form, key }))
        .map((props) => (
          <FormItem {...props} />
        ))}
    </div>
  )
}
