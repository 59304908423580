export const emptyFormTemplate = (blockUuid: string, siteUuid: string) => ({
  blocks: [
    {
      uuid: blockUuid,
      isTemplate: false,
      label: 'Neuer Block',
      name: 'new_block',
      condition: [],
      position: 0,
      sites: [
        {
          uuid: siteUuid,
          inputs: [],
          label: 'Neue Seite',
          name: 'new_site',
          position: 0,
        },
      ],
    },
  ],
})
