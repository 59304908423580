import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Logo } from '../shared/Logo'
import { EditorHelpButton } from '../editor/EditorHelpButton'
import { AuthButton } from './AuthButton'

interface props {
  className?: string
}

export const Header: FC<props> = ({ className }) => {
  return (
    <div className={`${className} bg-dark z-10`}>
      <div className='flex items-center h-full mx-auto max-w-8xl px-4'>
        <h1 className='mr-auto text-2xl'>
          <Link className='text-accent' to='/'>
            <Logo />
          </Link>
        </h1>
        <EditorHelpButton />
        <AuthButton />
      </div>
    </div>
  )
}
