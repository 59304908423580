import { useHistory } from 'react-router-dom'
import { v4 } from 'uuid'
import { Input, InputType, Select, Site } from '../backbone/types'
import { reorder } from '../backbone/utils'
import { useEditorActions } from './useEditorActions'
import { useEditorState } from './useEditorState'

export const useEditorSite = (blockUuid: string, uuid: string) => {
  const history = useHistory()
  const state = useEditorState()
  const { saveSite, removeSite } = useEditorActions()

  if (!blockUuid || !state?.blocks?.find((b) => b.uuid === blockUuid)) {
    history.replace('/editor')
    return {}
  }

  const _site = state.blocks
    ?.flatMap((b) => b.sites || [])
    ?.find((s) => s.uuid === uuid)

  if (!_site) {
    history.replace(`/editor/${state.uuid}/${blockUuid}`)
    return {}
  }

  return {
    site: _site,
    save: (site: Partial<Site>) =>
      saveSite(blockUuid, { ..._site, ...site, uuid }),
    remove: () => removeSite(_site.uuid),
    addField: (type: keyof typeof InputType) => {
      const field = {
        uuid: v4(),
        columns: 6,
        condition: [],
        position: _site.inputs?.length || 0,
        label: 'Neues Feld',
        name: 'new_field',
        __typename: type,
      } as Input

      if (type === 'Select') {
        ;(field as Select).isBranchingPoint = true
        ;(field as Select).options = []
      }

      saveSite(blockUuid, {
        ..._site,
        inputs: [...(_site.inputs || []), field],
      })
    },
    reorderFields: (source: number, destination: number) => {
      // console.log(source, destination)
      saveSite(blockUuid, {
        ..._site,
        inputs: reorder(_site.inputs, source, destination).map(
          (item, position) => ({ ...item, position })
        ),
      })
    },
  }
}
