import { MutationHookOptions, useMutation } from '@apollo/client'
import { SAVE_FORM } from '../backbone/queries'
import { Form, MutationSaveFormArgs } from '../backbone/types'
import { useEditorState } from '../hooks/useEditorState'
import { mapFormToFormInput } from './mapFormToFormInput'

export const useSaveForm = () => {
  const form = useEditorState()
  const [saveForm, request] = useMutation<
    { saveForm: Form },
    MutationSaveFormArgs
  >(SAVE_FORM)

  return {
    saveForm: (
      options?: MutationHookOptions<{ saveForm: Form }, MutationSaveFormArgs>
    ) => {
      if (!!form)
        saveForm({
          variables: mapFormToFormInput(form),
          refetchQueries: 'all',
          ...options,
        })
    },
    request,
  }
}
