import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type State = {
  path: string
  label: string
}[]

const initialState = [] as State

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setBreadcrumbs: (_, action: PayloadAction<State>) => action.payload,
  },
})

export const breadcrumbsReducer = breadcrumbsSlice.reducer

export const { setBreadcrumbs } = breadcrumbsSlice.actions
