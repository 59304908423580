import { v4 } from 'uuid'
import { Block, InputType, Select } from './types'

export const removeNestedItem = (
  target: { [key: string]: any[] },
  key: string,
  index: number
) => ({
  ...target,
  [key]: target[key].filter((_, i) => i !== index),
})

export const parseTimestampToLocalDateString = (ts: number) => {
  const date = new Date(ts)
  return date.toLocaleDateString()
}

export const FieldTypes = [
  { label: 'Formulareingabe (kurz)', type: InputType.TextField },
  { label: 'Formulareingabe (mehrzeilig)', type: InputType.TextArea },
  { label: 'Info', type: InputType.InfoField },
  { label: 'Checkbox', type: InputType.CheckBox },
  { label: 'Auswahl', type: InputType.Select },
  { label: 'Dateiupload', type: InputType.FileUpload },
  { label: 'Wiederholung', type: InputType.RepeatBlockButton },
  { label: 'Weiter', type: InputType.NextBlockButton },
  { label: 'Formular absenden', type: InputType.SubmitFormButton },
]

export const FieldTypeInfoTexts = {
  [InputType.CheckBox]:
    'Dieses Feld erzeugt ein Kästchen zum anhaken und kann bspw. für Ja-Nein-Fragen genutzt werden.',
  [InputType.FileUpload]:
    'Dieses Feld erzeugt einen Bereich, in dem Klienten eine Datei hochladen können.',
  [InputType.InfoField]: 'Dieses Feld gibt Ihren eingegebenen Text aus.',
  [InputType.NextBlockButton]:
    'Mit diesem Button gelangt der Klient auf die nächste Seite, ohne vorher eine Auswahl treffen zu müssen.',
  [InputType.RepeatBlockButton]:
    'Dieses Feld erzeugt einen Button, der auf Klick den aktuellen Formularblock erneut durchläuft.',
  [InputType.Select]:
    'Dieses Feld erzeugt eine einfache Auswahl, die den Klienten bei Bedarf auf eine andere Seite weiterleitet.',
  [InputType.SubmitFormButton]:
    'Fügen Sie dieses Feld auf der letzten Seite Ihres letzten Formularblocks ein, um eine Download-Möglichkeit der Daten für den Klienten anzubieten. Dieses Feld sitzt immer zentriert',
  [InputType.TextArea]:
    'Dieses Feld erzeugt ein mehrzeiliges Eingabefeld für ein Formular, bspw. für einen Kommentar.',
  [InputType.TextField]:
    'Dieses Feld erzeugt ein einfaches, kurzes Eingabefeld für ein Formular, bspw. für einen Vor- oder Nachnamen.',
}

export const randomColor = () => {
  const literals = '0123456789abcdef'.split('')

  return (
    '#' +
    new Array(6)
      .fill(0)
      .map(() => Math.sqrt(Math.random()))
      .map((i) => i * 15)
      .map(Math.round)
      .map((index) => literals[index])
      .join('')
  )
}

export const reorder = <T,>(list: T[], source: number, destination: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(source, 1)
  result.splice(destination, 0, removed)

  return result
}

export const patchUuids = (block?: Block): Block | null =>
  !block
    ? null
    : {
        ...block,
        uuid: v4(),
        sites: block.sites.map((site) => ({
          ...site,
          uuid: v4(),
          inputs: site.inputs
            .map((input) => ({
              ...input,
              uuid: v4(),
            }))
            .map((i) =>
              i.__typename === 'Select'
                ? ({
                    ...i,
                    options: i.options.map((o) => ({ ...o, uuid: v4() })),
                  } as Select)
                : i
            ),
        })),
      }
