import { FC } from 'react'
import { useUuid } from '../backbone/hooks'
import { labelToName } from '../backbone/labelToName'
import { Input } from '../backbone/types'
import { AlignCenter } from '../icons/AlignCenter'
import { AlignLeft } from '../icons/AlignLeft'

interface props {
  label: string
  className?: string
  input: Input
  showAlign?: boolean
  saveInput: (v: Partial<Input>) => void
}

export const FieldConfiguratorTextInput: FC<props> = ({
  label,
  input,
  saveInput,
  showAlign,
  className,
}) => {
  const uuid = useUuid()
  const buttonClasses =
    'w-8 h-full p-1 flex items-center justify-center clickable hover:bg-gray-100 border'
  return (
    <div className={'flex items-center w-full space-x-2 ' + className}>
      <label
        htmlFor={uuid}
        className='form-label inline-block text-gray-700 w-1/6'
      >
        {label}
      </label>
      <div className='w-full flex space-x-2'>
        <input
          value={input.label}
          onChange={(e) =>
            saveInput?.({
              label: e.currentTarget.value,
              name: labelToName(e.currentTarget.value),
            })
          }
          type='text'
          className='form-control block flex-1 px-3 py-1.5 text-base font-normal text-gray-700 bg-white  bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none shadow-md'
          id={uuid}
        />
        {!!showAlign && (
          <div className='rounded overflow-hidden flex p-0 border border-solid border-gray-300 shadow-md'>
            <AlignLeft
              onClick={() => saveInput({ isLabelCentered: false })}
              className={`${buttonClasses} rounded-l px-2 py-1.5 border-0 ${
                !input.isLabelCentered &&
                'bg-font text-white hover:bg-font hover:opacity-100 hover:text-white'
              }`}
            />
            <AlignCenter
              onClick={() => saveInput({ isLabelCentered: true })}
              className={`${buttonClasses} rounded-r px-2 py-1.5 border-0 ${
                input.isLabelCentered &&
                'bg-font text-white hover:bg-font hover:opacity-100 hover:text-white'
              }`}
            />
          </div>
        )}
      </div>
    </div>
  )
}
