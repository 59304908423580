import { FC, SVGProps } from 'react'

export const ChainIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
<svg 
	width="16" 
	height="16" 
	viewBox="0 0 16 16" 
	fill="none" 
	xmlns="http://www.w3.org/2000/svg" 
	{...props}
	>
	<path 
		d="M4.52174 15.9996C3.32919 15.9996 2.23602 15.4996 1.34161 14.6996C-0.447205 12.8996 -0.447205 10.0996 1.34161 8.29961L2.03727 7.59961L3.42857 8.99961L2.73292 9.69961C1.73913 10.6996 1.73913 12.2996 2.73292 13.2996C3.72671 14.2996 5.31677 14.2996 6.31056 13.2996L9.29193 10.2996C10.2857 9.29961 10.2857 7.69961 9.29193 6.69961L8.59627 5.99961L9.98758 4.59961L10.6832 5.29961C12.472 7.09961 12.472 9.89961 10.6832 11.6996L7.70186 14.6996C6.90683 15.4996 5.71429 15.9996 4.52174 15.9996Z" 
		fill="currentColor"
	/>
	<path 
		d="M6.01243 11.4L5.31677 10.7C3.52795 8.9 3.52795 6.1 5.31677 4.3L8.29814 1.3C9.19255 0.4 10.2857 0 11.4783 0C12.6708 0 13.764 0.5 14.6584 1.3C16.4472 3.1 16.4472 5.9 14.6584 7.7L13.9627 8.4L12.5714 7L13.2671 6.3C14.2609 5.3 14.2609 3.7 13.2671 2.7C12.2733 1.7 10.6832 1.7 9.68944 2.7L6.70808 5.7C5.71429 6.7 5.71429 8.3 6.70808 9.3L7.40373 10L6.01243 11.4Z" 
		fill="currentColor"
	/>
</svg>

)