export const helpText = `

# Das ist ein Hilfe-Dokument auf Markdownbasis

Hier kann formatierter Text gespeichert werden:

## Überschriften

### Überschriften

#### Überschriften

- listen
- sind
- möglich

1. so
2. wie
3. Aufzählungen

[Außerdem gibt es auch Links zu anderen Seiten](https://noto-wp.taenzer.work/)

`
