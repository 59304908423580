import { useLocation, useHistory } from 'react-router'

export const useSearchParams = (): { [key: string]: string | undefined } => {
  const location = useLocation()
  return location.search
    .slice(1)
    .split('&')
    .map((pair) => pair.split('='))
    .filter((pair) => pair.length === 2)
    .reduce((obj, [key, val = '']) => ({ [key]: val, ...obj }), {})
}

export const useSetSearchParam = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const searchParams = useSearchParams()
  const objToString = (obj: Object) =>
    Object.entries(obj)
      .map((e) => e.join('='))
      .join('&')

  return (key: string, val: string) => {
    const params = { ...searchParams, [key]: val }
    const search = objToString(params)

    history.replace({ pathname, search })
  }
}
