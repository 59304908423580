import React, { FC, HTMLProps, useState } from 'react'
import { useUuid } from '../backbone/hooks'

export const TextInputCompact: FC<HTMLProps<HTMLInputElement>> = (props) => {
  const uuid = useUuid()
  const [focus, setFocus] = useState(false)
  const labelClasses =
    props.value || focus
      ? 'ml-1 text-sm -mt-5'
      : 'leading-10 ml-3 text-gray-500'
  return (
    <div className='relative mt-6 mb-2'>
      {props.label && (
        <label
          className={`absolute transition-all duration-150 ${labelClasses}`}
          htmlFor={props.id || uuid}>
          {props.label}
        </label>
      )}
      <input
        autoComplete='off'
        className={`rounded max-w-2xl w-full min-w-0 ${
          !!props.disabled && 'bg-gray-100'
        }`}
        type='text'
        id={props.id || uuid}
        {...props}
        placeholder=''
        onFocus={() => setFocus(true)}
        onBlur={(e) => {
          setFocus(false)
          props.onBlur?.(e)
        }}
      />
    </div>
  )
}
