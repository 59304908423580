import { FC } from 'react'
import { MaterialSelect } from '../shared/MaterialSelect'

interface props {
  filter: string
  setFilter: (v: string) => void
}

export const FolderFilterSelector: FC<props> = ({ filter, setFilter }) => {
  const options = [
    { value: '', label: 'Filtern' },
    { value: 'drafts', label: 'Entwürfe' },
    { value: 'published', label: 'Veröffentlichte Formulare' },
  ]
  return (
    <MaterialSelect
      onChange={(e) => setFilter(e.currentTarget.value)}
      value={filter}>
      {options.map((o, i) => (
        <option value={o.value} key={i}>
          {o.label}
        </option>
      ))}
    </MaterialSelect>
  )
}
