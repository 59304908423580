import { Redirect } from 'react-router-dom'
import { useEditorActions } from '../hooks/useEditorActions'
import { useEditorRouteParams } from '../hooks/useEditorRouteParams'
import { useEditorState } from '../hooks/useEditorState'
import { useLoadForm } from '../hooks/useLoadForm'
import { useSnackbar } from '../hooks/useSnackbar'
import { LoadingIndicator } from '../ui/LoadingIndicator'
import { EditorHeader } from './EditorHeader'
import { FormChildrenList } from './FormChildrenList'
import { FormConfigurator } from './FormConfigurator'

export const EditorLoadFormHandler = () => {
  const { form } = useEditorRouteParams()
  const editorState = useEditorState()
  const { loadForm } = useEditorActions()
  const query = useLoadForm(form, {
    onCompleted: ({ form }) => {
      if (!!form && form.uuid !== editorState?.uuid) loadForm(form)
    },
  })
  const { show: showSnackbar } = useSnackbar({
    title: 'Fehler',
    text: 'Beim Laden des Formulars ist ein Fehler aufgetreten!',
  })

  if (!!query.error) {
    showSnackbar()
    return <Redirect to='editor/new' />
  }

  return query.loading ? (
    <LoadingIndicator />
  ) : (
    <>
      <EditorHeader title='Formular bearbeiten' />
      <FormConfigurator />
      <FormChildrenList />
    </>
  )
}
