import { QueryHookOptions, useQuery } from '@apollo/client'
import { GET_FORM } from '../backbone/queries'
import { Form, QueryFormArgs } from '../backbone/types'

export const useLoadForm = (
  uuid: string,
  options: QueryHookOptions<{ form: Form }, QueryFormArgs> = {}
) => {
  const query = useQuery<{ form: Form }, QueryFormArgs>(GET_FORM, {
    variables: { uuid },
    ...options,
  })
  return query
}
