import React, { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { NewBlockHandler } from './NewBlockHandler'
import { useBreadcrumbs } from '../hooks/useBreadcrumbs'
import { useEditorState } from '../hooks/useEditorState'
import { BlockConfigurator } from './BlockConfigurator'
import { EditorHeader } from './EditorHeader'
import { EditorLoadFormHandler } from './EditorLoadFormHandler'
import { EditorNewFormHandler } from './EditorNewFormHandler'
import { EditorNewSiteHandler } from './EditorNewSiteHandler'
import { SiteConfigurator } from './SiteConfigurator'

export const Editor: FC = () => {
  useBreadcrumbs([
    { label: 'Admin-Bereich', path: '/' },
    { label: 'Editor', path: '.' },
  ])
  const state = useEditorState()
  return (
    <Switch>
      <Route path='/editor/new/:folder' component={EditorNewFormHandler} />
      {!state.uuid && (
        <Route path='/editor/:form' component={EditorLoadFormHandler} />
      )}
      <Route path='/editor/:form/new'>
        <EditorHeader title='Formularblock erstellen' />
        <NewBlockHandler />
      </Route>
      <Route path='/editor/:form/:block/new' component={EditorNewSiteHandler} />
      <Route path='/editor/:form/:block/:site'>
        <EditorHeader title='Seite bearbeiten' />
        <SiteConfigurator />
      </Route>
      <Route path='/editor/:form/:block'>
        <EditorHeader title='Formularblock bearbeiten' />
        <BlockConfigurator />
      </Route>
      <Route path='/editor/:form' component={EditorLoadFormHandler} />
      <Route path='/editor' render={() => <Redirect to='/folder' />} />
    </Switch>
  )
}
