import React, { FC } from 'react'
import { Link, Redirect, useRouteMatch } from 'react-router-dom'
import { MaterialButton } from '../shared/MaterialButton'

interface props {
  title: string
}
interface routeParams {
  form: string
  block: string
}

export const EditorHeader: FC<props> = ({ title }) => {
  const match = useRouteMatch<routeParams>([
    '/editor/:form/:block',
    '/editor/:form',
  ])
  const { block = 'new', form } = match!!.params

  return !form ? (
    <Redirect to='/editor/new' />
  ) : (
    <>
      <h1 className='text-2xl text-black mb-8 font-bold'>Editor</h1>
      <div className='flex items-center space-x-4 mb-2'>
        <h2 className='mr-auto text-black text-lg '>{title}</h2>
        <Link to={`/editor/${form}/new`}>
          <MaterialButton>+ Neuen Formularblock erstellen</MaterialButton>
        </Link>
        {block !== 'new' && (
          <Link to={`/editor/${form}/${block}/new`}>
            <MaterialButton>+ Neue Seite erstellen</MaterialButton>
          </Link>
        )}
      </div>
      <hr />
    </>
  )
}
