import { labelToName } from '../backbone/labelToName'
import {
  Block,
  Form,
  MutationSaveFormArgs,
  Option,
  OptionInput,
  Select,
} from '../backbone/types'

export const mapFormToFormInput = (formPayload: Form): MutationSaveFormArgs => {
  const { blocks = [], __typename, ...form } = formPayload
  const sites =
    blocks.flatMap((b) =>
      b.sites.map((s, position) => ({ ...s, blockUuid: b.uuid, position }))
    ) || []
  const fields =
    sites.flatMap((s) =>
      s.inputs.map((input, position) => ({
        ...input,
        columns: input.columns || 6,
        siteUuid: s.uuid,
        position,
        isBranchingPoint: (input as Select).isBranchingPoint,
        isLabelCentered: !!input.isLabelCentered,
      }))
    ) || []

  const options: OptionInput[] =
    fields
      .filter((f) => f.__typename === 'Select')
      .map((f) => f as Select)
      .flatMap(
        (s) =>
          (s.options as Option[])
            .map((o) => ({
              uuid: o.uuid,
              label: o.label,
              siteLinkUuid: o.siteLinkUuid,
              selectUuid: s.uuid,
              helpText: o.helpText,
              condition: o.condition,
              value: labelToName(o.label),
            }))
            .filter((o) => !!o?.uuid && !!o.label) || []
      ) || []

  return {
    form,
    blocks: blocks.map((b, position) => ({
      ...b,
      isTemplate: !!b.isTemplate,
      formUuid: form.uuid,
      name: labelToName(b.label),
      condition:
        b.condition?.map((s) => ({ ...s, __typename: undefined })) || [],
      __typename: undefined,
      sites: undefined,
      position,
    })),
    sites: sites.map((s) => ({
      ...s,
      name: labelToName(s.label),
      inputs: undefined,
      __typename: undefined,
    })),
    fields: fields.map((f) => ({
      ...f,
      pattern: undefined,
      type: f.__typename || '',
      options: undefined,
      specialType: undefined,
      condition: f.condition.map((s) => ({ ...s, __typename: undefined })),
      maxLength: undefined,
      __typename: undefined,
    })),
    options: options.map((o) => ({
      ...o,
      condition:
        o.condition?.map((s) => ({ ...s!, __typename: undefined })) || [],
    })),
  }
}

export const mapBlockToBlockInput = (blockPayload: Block) => {
  const { sites, ...block } = blockPayload

  return block
}
