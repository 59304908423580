import React, { FC } from 'react'
import { useUuid } from '../backbone/hooks'

interface props {
  checked?: boolean
  onClick?: () => void
  className?: string
}
export const MaterialCheckbox: FC<props> = ({
  onClick,
  checked,
  children,
  className,
}) => {
  const id = useUuid()
  return (
    <div className={`form-check ${className}`}>
      <label className={`form-check-label inline-block text-dark`} htmlFor={id}>
        <input
          onClick={onClick}
          onChange={() => {}}
          className='form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer accent-accent'
          type='checkbox'
          id={id}
          checked={checked}
        />
        {children}
      </label>
    </div>
  )
}
