import { useMutation } from '@apollo/client'
import { MOVE_FORM } from '../backbone/queries'
import { MutationMoveFormArgs } from '../backbone/types'

export const useMoveFormMutation = () => {
  const [moveForm, state] = useMutation<
    { moveForm: boolean },
    MutationMoveFormArgs
  >(MOVE_FORM, { refetchQueries: 'active' })

  return { moveForm, state }
}
