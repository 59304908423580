import axios, { AxiosResponse } from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import { Credentials, RegisterPayload } from '../redux/auth.types'
import { LoginSuccess } from './authApi.types'

const authClient = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.REACT_APP_AUTH_URL || 'http://localhost:4444',
  })
)

interface RefreshSuccess {
  accessToken: string
}

interface UpdatePasswordPayload {
  old: string
  new: string
  token: string
}

export const authApi = {
  login: (credentials: Credentials) =>
    authClient.post<any, AxiosResponse<LoginSuccess>>('/login', credentials),
  refresh: (refreshToken: string) =>
    authClient.post<any, AxiosResponse<RefreshSuccess>>('/refresh', '', {
      headers: { Authorization: `Bearer ${refreshToken}` },
    }),
  register: (payload: RegisterPayload) =>
    authClient.post<any, AxiosResponse<string>>('/register', payload),
  updatePassword: ({ token, ...payload }: UpdatePasswordPayload) =>
    authClient.put('/account/password', payload, {
      headers: { Authorization: `Bearer ${token}` },
    }),
}
