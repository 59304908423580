import { useMutation } from '@apollo/client'
import { UPDATE_FOLDER } from '../backbone/queries'
import { FolderInput, MutationUpdateFolderArgs } from '../backbone/types'

export const useUpdateFolder = (uuid: string) => {
  const [mutate, request] = useMutation<any, MutationUpdateFolderArgs>(
    UPDATE_FOLDER,
    { refetchQueries: 'active' }
  )
  return {
    update: (input: FolderInput) => mutate({ variables: { uuid, input } }),
    request,
  }
}
