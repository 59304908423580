import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { usePagination } from '../hooks/usePagination'
import { Arrow } from '../icons/Arrow'

export const PaginationView = () => {
  const isForm = useRouteMatch('/folder/:uuid')
  const { itemCount, maxPage, page, goToNextPage, goToPreviousPage } =
    usePagination(!!isForm)
  return (
    <Route path='/folder'>
      {itemCount !== undefined && (
        <div className='text-lg font-normal mr-auto ml-[30px]'>
          {itemCount} Objekt{itemCount !== 1 && 'e'}
        </div>
      )}
      {itemCount !== undefined && (
        <div className='text-lg font-normal mr-auto ml-[30px]'>&nbsp;</div>
      )}
      {page > 1 && (
        <div
          onClick={goToPreviousPage}
          className='space-x-2 clickable text-lg font-normal flex items-center'>
          <Arrow className='transform' />
          <span>Vorherige Seite</span>
        </div>
      )}
      <div className='w-4'>&nbsp;</div>
      {maxPage !== undefined && (
        <div className='text-lg font-normal'>
          Seite {page} von {maxPage}
        </div>
      )}
      <div className='w-4'>&nbsp;</div>
      {page !== maxPage && (
        <div
          onClick={goToNextPage}
          className='space-x-2 clickable text-lg font-normal flex items-center'>
          <span>Nächste Seite</span>
          <Arrow className='transform rotate-180' />
        </div>
      )}
    </Route>
  )
}
