import { FC, SVGProps } from 'react'

export const SearchIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width='16'
    height='18'
    viewBox='0 0 16 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M12.7 12.2502C13.6 11.0502 14.1 9.6502 14.1 8.0502C14.1 4.1502 11 0.950195 7.1 0.950195C3.2 0.950195 0 4.1502 0 8.0502C0 11.9502 3.2 15.1502 7.1 15.1502C8.7 15.1502 10.2 14.6502 11.3 13.7502L14.3 16.7502C14.5 16.9502 14.8 17.0502 15 17.0502C15.2 17.0502 15.5 16.9502 15.7 16.7502C16.1 16.3502 16.1 15.7502 15.7 15.3502L12.7 12.2502ZM7.1 13.0502C4.3 13.0502 2 10.8502 2 8.0502C2 5.2502 4.3 2.9502 7.1 2.9502C9.9 2.9502 12.2 5.2502 12.2 8.0502C12.2 10.8502 9.9 13.0502 7.1 13.0502Z'
      fill='#495057'
    />
  </svg>
)
