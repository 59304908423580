import { useDispatch } from 'react-redux'
import { useAppSelector } from '../redux/store'
import { setSorting, Sorting } from '../redux/ui'

export const fieldSorter =
  <T,>(key: keyof T, reverse = false) =>
  (a: T, b: T) => {
    const first = reverse ? b[key] : a[key]
    const second = reverse ? a[key] : b[key]

    return first > second ? -1 : 1
  }

export const FolderItemSorter: {
  [key in Sorting]: ReturnType<typeof fieldSorter>
} = {
  'date asc': fieldSorter('lastUpdated'),
  'date desc': fieldSorter('lastUpdated', true),
  'title asc': fieldSorter('label'),
  'title desc': fieldSorter('label', true),
  'none': fieldSorter('label'),
}

export const useFolderItemSorting = () => {
  const dispatch = useDispatch()
  const sorting = useAppSelector((state) => state.ui.sorting)

  return {
    sorting,
    setSorting: (type: Sorting) => {
      dispatch(setSorting(type))
    },
    sortingFunction: FolderItemSorter[sorting],
  }
}
