import React, { FC } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useEditorActions } from '../hooks/useEditorActions'
import { ItemViewToggle } from '../shared/ItemViewToggle'
import { AddFormButton } from './AddFormButton'
import { FolderFilterSelector } from './FolderFilterSelector'
import { FolderItemSortingSelector } from './FolderItemSortingSelector'

interface props {
  filterProps: { filter: string; setFilter: (v: string) => void }
}
export const SubFolderViewHeader: FC<props> = ({ filterProps }) => {
  const match = useRouteMatch<{ uuid: string }>('/folder/:uuid')

  const history = useHistory()
  const { createNewForm } = useEditorActions()
  const addForm = () => {
    createNewForm(match?.params.uuid || '')
    history.push('/editor/new')
  }
  return (
    <>
      <h1 className='text-2xl font-bold text-black mb-8'>Formulare</h1>
      <div className='flex gap-4 my-2 w-full justify-start'>
        <FolderItemSortingSelector />
        <FolderFilterSelector {...filterProps} />
        <ItemViewToggle />
        <AddFormButton onClick={addForm} className='ml-auto' />
      </div>
    </>
  )
}
