import { useGridViewToggle } from '../backbone/useGridViewToggle'
import { GridView } from '../icons/GridView'
import { ListView } from '../icons/ListView'

export const ItemViewToggle = () => {
  const passiveToggleStyle = 'text-dark bg-white'
  const activeToggleStyle = 'text-white bg-dark'
  const baseToggleStyle = `
    fill-current inline-block px-6 py-2.5 bg-dark-600 font-medium text-xs leading-tight uppercase hover:bg-dark-700 focus:bg-dark-700 focus:outline-none focus:ring-0 active:bg-dark-800 transition duration-150 ease-in-out
    `
  const { isGridView, setIsGridView } = useGridViewToggle()
  return (
    <div className='flex items-center justify-center'>
      <div
        className='inline-flex shadow-md hover:shadow-lg focus:shadow-lg'
        role='group'>
        <button
          onClick={() => {
            setIsGridView(false)
          }}
          type='button'
          className={`rounded-l clickable ${baseToggleStyle} ${
            !isGridView ? activeToggleStyle : passiveToggleStyle
          }`}>
          <ListView className='!fill-current !text-inherit' />
        </button>
        <button
          onClick={() => {
            setIsGridView(true)
          }}
          type='button'
          className={`rounded-r clickable ${baseToggleStyle} ${
            isGridView ? activeToggleStyle : passiveToggleStyle
          }`}>
          <GridView className='!fill-current !text-inherit' />
        </button>
      </div>
    </div>
  )
}
