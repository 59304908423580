import { useDispatch } from 'react-redux'
import {
  setFoldersPaginationState,
  setFormsPaginationState,
} from '../redux/pagination'
import { useAppSelector } from '../redux/store'

export const usePagination = (forms = false, maxItems = 20) => {
  const setPaginationState = forms
    ? setFormsPaginationState
    : setFoldersPaginationState
  const dispatch = useDispatch()
  const {
    totalItemCount = 1,
    page = 1,
    maxPage = 1,
  } = useAppSelector((s) => (forms ? s.pagination.forms : s.pagination.folders))

  const navigatePage = (backwards?: boolean) => () => {
    const currentPage = page || 1
    const upcomingPage = !!backwards
      ? Math.max(1, currentPage - 1)
      : Math.min(maxPage, currentPage + 1)
    dispatch(setPaginationState({ page: upcomingPage }))
  }

  const itemCount = !totalItemCount
    ? 0
    : page === maxPage
    ? totalItemCount % maxItems
    : maxItems

  return {
    setTotalItemCount: (totalItemCount: number) =>
      dispatch(
        setPaginationState({
          page: 1,
          totalItemCount,
          maxPage: Math.ceil(totalItemCount / maxItems) || 1,
        })
      ),
    goToNextPage: navigatePage(),
    getPageItems: <T>(items?: T[]) =>
      !items
        ? ([] as T[])
        : items.slice(maxItems * (page - 1), maxItems * (page - 1) + maxItems),
    goToPreviousPage: navigatePage(true),
    maxPage,
    page,
    itemCount,
    totalItemCount,
  }
}
