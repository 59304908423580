import { useQuery } from '@apollo/client'
import { GET_ALL_FOLDERS } from '../backbone/queries'
import { usePagination } from '../hooks/usePagination'
import { Folder } from './types'

export const useRootFolderItems = () => {
  const { setTotalItemCount, getPageItems } = usePagination(false)
  const query = useQuery<{ allFolders: Folder[] }>(GET_ALL_FOLDERS, {
    onCompleted: (data) => {
      setTotalItemCount(data.allFolders.length || 0)
    },
  })

  return {
    loading: query.loading,
    folders: getPageItems(query.data?.allFolders),
  }
}
