import { useEditorRouteParams } from '../hooks/useEditorRouteParams'
import { useEditorSite } from '../hooks/useEditorSite'
import { TrashIcon } from '../icons/TrashIcon'
import { MaterialInput } from '../shared/MaterialInput'
import { AddFieldSelector } from './AddFieldSelector'

export const SiteConfiguratorHeader = () => {
  const { block = '', site: siteUuid = '' } = useEditorRouteParams()
  const { save, site, remove } = useEditorSite(block, siteUuid)

  return (
    <div className='flex items-center justify-start'>
      <MaterialInput
        value={site?.label}
        onChange={(label) => save?.({ label })}
        label='Titel:'
      />
      <TrashIcon
        className='ml-2 mr-auto clickable fill-current active:text-accent'
        onClick={remove}
      />
      <AddFieldSelector blockUuid={block} siteUuid={siteUuid} />
    </div>
  )
}
