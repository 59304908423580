import React, { FC, MouseEvent, useRef } from 'react'
import ReactModal from 'react-modal'
import { useClickAwayListener } from '../backbone/useClickAwayListener'
import { MaterialButton } from './MaterialButton'

interface props {
  open: boolean
  setOpen: (v: boolean) => void
  title: string
  buttons?: Button[]
  submit: Button
}

interface Button {
  action: (e: MouseEvent) => void
  label: string
}

export const Modal: FC<props> = ({
  open,
  setOpen,
  children,
  title,
  submit,
  buttons = [],
}) => {
  const ref = useRef<HTMLDivElement>(null)
  useClickAwayListener(ref, () => setOpen(false))

  return (
    <ReactModal
      appElement={document.getElementById('root') as HTMLElement}
      isOpen={open}
      className='w-screen min-h-screen bg-gray-100 flex items-center justify-center fixed-top bg-opacity-70'>
      <div className='max-w-[33vw] w-full' ref={ref}>
        <div className='rounded max-w-[33vw] w-full min-h-[240px] shadow bg-white overflow-hidden flex flex-col'>
          <div className='h-20 bg-accent text-white items-center flex px-5'>
            <h2 className='font-semibold text-lg'>{title}</h2>
          </div>
          <div className='flex flex-col flex-1 justify-evenly p-5'>
            <div className='max-h-[33vh] overflow-auto'>{children}</div>
            <div className='flex justify-end'>
              <div className='flex space-x-2 justify-center'>
                {buttons.map((b, i) => (
                  <MaterialButton
                    onClick={b.action}
                    key={i}
                    type='button'
                    className='!bg-transparent !text-dark !hover:bg-gray-200 !hover:shadow-none !focus:bg-gray-300 shadow-none'>
                    {b.label}
                  </MaterialButton>
                ))}
                <MaterialButton
                  type='submit'
                  onClick={submit.action}
                  className='font-bold'>
                  {submit.label}
                </MaterialButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  )
}
