import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Site } from '../backbone/types'

export const BlockNavbarListItem: FC<{ site: Site; parentPath: string }> = ({
  site: { label, uuid },
  parentPath,
}) => {
  const path = `${parentPath}/${uuid}`
  return (
    <li className='inline-block leading-9'>
      <NavLink
        title={label}
        activeClassName='text-accent !border-accent truncate'
        className='clickable text-lg hover:text-accent hover:border-accent truncate space-x-2 block leading-9 border-l-[3px] border-light ml-[-3px] pl-[24px]'
        to={path}>
        <span className='mx-2 ml-5 text-lg'>&#8226;</span>
        <span className='truncate'>{label}</span>
      </NavLink>
    </li>
  )
}
