import { useMutation } from '@apollo/client'
import { SET_FORM_STATUS } from '../backbone/queries'
import { Form, MutationSetFormStatusArgs } from '../backbone/types'

export const useSetFormStatus = (uuid: string) => {
  const [mutate, request] = useMutation<
    { setFormStatus: Form },
    MutationSetFormStatusArgs
  >(SET_FORM_STATUS, {
    refetchQueries: 'active',
  })
  return {
    loading: request.loading,
    setStatus: (isDraft: boolean) => mutate({ variables: { uuid, isDraft } }),
  }
}
