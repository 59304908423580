import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Form } from '../backbone/types'
import { MenuProps } from '../backbone/useContextMenuControls'
import { useGridViewToggle } from '../backbone/useGridViewToggle'
import { parseTimestampToLocalDateString } from '../backbone/utils'
import { DraftIcon } from '../icons/DraftIcon'
import { FormIcon } from '../icons/FormIcon'

interface props {
  menuProps: MenuProps
  form: Form
}

export const FormItemView: FC<props> = ({ form, menuProps }) => {
  const Icon = form.isDraft ? DraftIcon : FormIcon
  const { isGridView } = useGridViewToggle()
  return (
    <Link
      to={`/editor/${form.uuid}`}
      ref={menuProps.anchorRef}
      onContextMenu={menuProps.openContextMenu}
      className={`flex items-center gap-2 border-[1px] ${
        isGridView ? 
        'flex-col flex items-center gap-2 py-4 w-[140px] border-transparent' 
        : 
        'flex-row w-full py-4 justify-between border-b-gray-300 border-t-transparent border-r-transparent border-l-transparent'}
        ${menuProps.isOpen && 'rounded !border-accent'}`}
      >
      <div
        className={`${
          isGridView
            ? 'flex flex-col items-center gap-2'
            : 'flex flex-row items-center'
        }`}>
        <Icon className={`${isGridView ? 'w-12 h-12' : 'w-12 mr-4 h-10'}`} />
        <h2 className={`${isGridView ? 'text-center break-all w-[140px]' : 'min-w-fit'}`}>
          {form.label}
        </h2>
      </div>
      {!isGridView && (
        <>
          <div className='!ml-auto'>
            {parseTimestampToLocalDateString(form.lastUpdated)}
          </div>
          <div className='w-[120px] flex justify-end'>
            {form.isDraft ? 'In Bearbeitung' : 'Veröffentlicht'}
          </div>
        </>
      )}
    </Link>
  )
}
