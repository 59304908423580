import { Input } from '../backbone/types'
import { EditorFieldWrapper } from './EditorFieldWrapper'
import { FieldGroupPlaceholder } from './FieldGroupPlaceholder'

export const EditorFieldGroup = ({
  fields,
  remove,
  reorder,
  save,
  group,
}: {
  fields: Input[]
  save: (u: string) => (i: Partial<Input>) => void
  remove: (u: string) => () => void
  reorder: (s: number, d: number) => void
  group?: string
}) => (
  <div className='rounded w-full border-2 border-dashed flex flex-col relative pb-2'>
    {!fields.length ? (
      <FieldGroupPlaceholder />
    ) : (
      fields
        .slice()
        .sort((f1, f2) => f1.position - f2.position)
        .map((input, index) => (
          <EditorFieldWrapper
            saveInput={save(input.uuid)}
            removeInput={remove(input.uuid)}
            reorder={reorder}
            input={input}
            key={input.uuid + index}
          />
        ))
    )}
    <div className='absolute -bottom-3 right-10 bg-white px-2'>
      {group || 'Standard'}-Gruppe
    </div>
  </div>
)
