import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UserIcon } from '../icons/UserIcon'
import { toggleLoginStatus } from '../redux/auth'
import { RootState } from '../redux/store'

export const UserButton = () => {
  const alias = useSelector((state: RootState) => state.auth.alias) || ''
  const dispatch = useDispatch()
  const temporaryFn_toggleLoginStatus = () => {
    dispatch(toggleLoginStatus())
  }
  return (
    <div onClick={temporaryFn_toggleLoginStatus} className='text-white'>
      <UserIcon />
      <span>{alias} abmelden</span>
    </div>
  )
}
