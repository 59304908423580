import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 } from 'uuid'
import { Block, Form, Site } from '../backbone/types'

type State = Form

const initialState = {} as State

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    saveForm: (state, action: PayloadAction<Partial<Form>>) => ({
      ...state,
      ...action.payload,
    }),
    loadForm: (_, action: PayloadAction<State>) => action.payload,
    createNewForm: (state, action: PayloadAction<string>) => ({
      ...state,
      isDraft: true,
      blocks: [] as Block[],
      folderUuid: action.payload,
      label: 'Neues Formular',
      name: 'new',
      uuid: v4(),
    }),
    saveBlock: (state, action: PayloadAction<Block>) => {
      const block = action.payload
      if (!state.blocks.find((b) => b.uuid === block.uuid))
        state.blocks.push(block)
      state.blocks = state.blocks
        .map((b) => (b.uuid !== block.uuid ? b : block))
        .map((block, position) => ({ ...block, position }))
    },
    removeBlock: (state, action: PayloadAction<string>) => {
      const uuid = action.payload
      state.blocks = state.blocks
        .filter((b) => b.uuid !== uuid)
        .map((block, position) => ({ ...block, position }))
    },
    saveSite: (
      state,
      action: PayloadAction<{ blockUuid: string; site: Site }>
    ) => {
      const { blockUuid, site } = action.payload
      const block = state.blocks.find((b) => b.uuid === blockUuid)

      if (!block) return state
      if (!block.sites) block.sites = []
      if (!block.sites.find((s) => s.uuid === site.uuid)) block.sites.push(site)

      block.sites = block.sites
        .map((s) => (s.uuid !== site.uuid ? s : site))
        .map((site, position) => ({ ...site, position }))
    },
    removeSite: (state, action: PayloadAction<string>) => {
      const uuid = action.payload
      state.blocks = state.blocks
        .map((block) => ({
          ...block,
          sites: block.sites?.filter((site) => site.uuid !== uuid),
        }))
        .map((site, position) => ({ ...site, position }))
    },
  },
})

export const editorReducer = editorSlice.reducer

export const {
  loadForm,
  saveForm,
  createNewForm,
  saveBlock,
  removeBlock,
  removeSite,
  saveSite,
} = editorSlice.actions
