import React, { FC } from 'react'
import 'draft-js/dist/Draft.css'
import { RichTextInput } from './RichTextInput'

interface props {
  label?: string
  className?: string
  value?: string
  onChange: (v: string) => void
}

export const FieldConfiguratorFormattedTextInput: FC<props> = ({
  label,
  value,
  onChange,
}) => {
  return (
    <div className='flex my-2'>
      <div className='flex items-center space-x-2 w-full'>
        {!!label && (
          <label className='form-label inline-block mb-2 text-dark w-1/6 test'>
            Text
          </label>
        )}
        <RichTextInput value={value} onChange={onChange} />
      </div>
    </div>
  )
}
