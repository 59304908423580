import { useMutation } from '@apollo/client'
import { v4 } from 'uuid'
import { SAVE_FORM } from '../backbone/queries'
import { Form, MutationSaveFormArgs } from '../backbone/types'
import { mapFormToFormInput } from '../editor/mapFormToFormInput'
import { useLoadForm } from './useLoadForm'

const exchangeFormUuids = (form: Form | undefined): Form | undefined =>
  !form
    ? undefined
    : {
        ...form,
        isDraft: true,
        label: `${form.label} (Kopie)`,
        uuid: v4(),
        blocks: form.blocks.map((b) => ({
          ...b,
          uuid: v4(),
          sites: b.sites.map((s) => ({
            ...s,
            uuid: v4(),
            inputs: s.inputs
              .map((i) => ({ ...i, uuid: v4() }))
              .map((i) =>
                i.__typename !== 'Select'
                  ? i
                  : {
                      ...i,
                      options: i.options.map((o) => ({ ...o!, uuid: v4() })),
                    }
              ),
          })),
        })),
      }

export const useDuplicateForm = (uuid: string) => {
  const payload = useLoadForm(uuid)
  const form = payload.data?.form
  const [saveForm, request] = useMutation<
    { saveForm: Form },
    MutationSaveFormArgs
  >(SAVE_FORM)

  const duplicate = exchangeFormUuids(form)

  return {
    request,
    duplicate: () =>
      !duplicate
        ? undefined
        : saveForm({
            variables: mapFormToFormInput(duplicate),
            refetchQueries: 'all',
          }),
  }
}
