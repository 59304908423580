import { FC, SVGProps } from 'react'

export const ListView: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    width='16'
    height='16'
    viewBox='0 0 16 16'
    xmlns='http://www.w3.org/2000/svg'>
    <path d='M16 1H6V3H16V1Z' />
    <path d='M16 7H6V9H16V7Z' />
    <path d='M16 13H6V15H16V13Z' />
    <path d='M3 0H1C0.4 0 0 0.4 0 1V3C0 3.6 0.4 4 1 4H3C3.6 4 4 3.6 4 3V1C4 0.4 3.6 0 3 0Z' />
    <path d='M3 6H1C0.4 6 0 6.4 0 7V9C0 9.6 0.4 10 1 10H3C3.6 10 4 9.6 4 9V7C4 6.4 3.6 6 3 6Z' />
    <path d='M3 12H1C0.4 12 0 12.4 0 13V15C0 15.6 0.4 16 1 16H3C3.6 16 4 15.6 4 15V13C4 12.4 3.6 12 3 12Z' />
  </svg>
)
