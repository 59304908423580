import { Form, Maybe } from '../backbone/types'
import { reorder } from '../backbone/utils'
import { useEditorActions } from '../hooks/useEditorActions'
import { useAppSelector } from '../redux/store'
import { ChildrenSortingList } from './ChildrenSortingList'

export const FormChildrenList = () => {
  const state: Maybe<Form> = useAppSelector((state) => state.editor)
  const { saveForm } = useEditorActions()

  const deleteBlock = (uuid: string) =>
    saveForm({ blocks: state?.blocks.filter((b) => b.uuid !== uuid) })

  const sortBlocks = (source: number, target: number) =>
    saveForm({ blocks: reorder(state?.blocks || [], source, target) })

  const items =
    state?.blocks?.map((b, index) => ({
      ...b,
      position: index,
      linkPrefix: `/editor/${state?.uuid}`,
    })) || []

  return (
    <ChildrenSortingList
      title='Blocks in diesem Formular'
      items={items}
      onDelete={deleteBlock}
      reorder={sortBlocks}
    />
  )
}
