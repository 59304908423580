import { useRef, useState } from 'react'

export const useContextMenuControls = () => {
  const anchorRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const openContextMenu = (e: any) => {
    e.preventDefault()
    setIsOpen(true)
  }

  return { anchorRef, isOpen, setIsOpen, openContextMenu }
}

export type MenuProps = ReturnType<typeof useContextMenuControls>
