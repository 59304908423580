import { FC } from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

export const SidebarListItem: FC<{
  label: string
  path: string
  external?: boolean
  hideWhenInactive?: boolean
}> = ({ label, path, external, hideWhenInactive }) => {
  const onClick = () => {
    window.location.href = path
  }
  const match = useRouteMatch(path)
  const isActive = !!match
  const hide = !isActive && hideWhenInactive
  return hide ? null : (
    <li className={`leading-9 inline-flex my-4 ${isActive && 'text-current'}`}>
      <NavLink
        title={label}
        activeClassName='text-accent !border-accent'
        className='clickable text-lg hover:text-accent hover:border-accent truncate inline-block border-l-[3px] leading-9 border-light'
        onClick={external ? onClick : undefined}
        to={path}>
        <span className='mx-2 ml-5 text-lg'>&#8226;</span>
        <span className='text-lg font-normal'>{label}</span>
      </NavLink>
    </li>
  )
}
