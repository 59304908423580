import { useFolderItemSorting } from '../hooks/useFolderItemSorting'
import { Sorting } from '../redux/ui'
import { MaterialSelect } from '../shared/MaterialSelect'

type Option = { label: String; value: Sorting }

const options: Option[] = [
  { label: 'Sortieren', value: 'none' },
  { label: 'Änderungsdatum aufsteigend', value: 'date asc' },
  { label: 'Änderungsdatum absteigend', value: 'date desc' },
  { label: 'Titel aufsteigend', value: 'title asc' },
  { label: 'Titel absteigend', value: 'title desc' },
]

export const FolderItemSortingSelector = () => {
  const { setSorting, sorting } = useFolderItemSorting()
  return (
    <MaterialSelect
      className='pr-8'
      value={sorting}
      onChange={(e) => setSorting(e.currentTarget.value as Sorting)}>
      {options.map((o, i) => (
        <option key={i} value={o.value}>
          {o.label}
        </option>
      ))}
    </MaterialSelect>
  )
}
