import { FC } from 'react'
import { MaterialButton } from '../shared/MaterialButton'

interface props {
  className?: string
  onClick?: () => void
}

export const AddFormButton: FC<props> = (props) => {
  return <MaterialButton {...props}>+ Neues Formular erstellen</MaterialButton>
}
