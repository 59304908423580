import React from 'react'
import { SiteConfiguratorFieldList } from './SiteConfiguratorFieldList'
import { SiteConfiguratorHeader } from './SiteConfiguratorHeader'
import { useEditorBreadcrumbs } from './useEditorBreadcrumbs'

export const SiteConfigurator = () => {
  useEditorBreadcrumbs()
  return (
    <div className='flex flex-col gap-6 my-8'>
      <SiteConfiguratorHeader />
      <SiteConfiguratorFieldList />
    </div>
  )
}
