import React from 'react'
import { Redirect } from 'react-router-dom'
import { useEditorBlock } from '../hooks/useEditorBlock'
import { useEditorRouteParams } from '../hooks/useEditorRouteParams'
import { BlockChildrenList } from './BlockChildrenList'
import { BlockDeleteButton } from './BlockDeleteButton'
import { BlockNameInput } from './BlockNameInput'
import { BlockTemplateToggle } from './BlockTemplateToggle'
import { ConditionConfigurator } from './ConditionConfigurator'
import { useEditorBreadcrumbs } from './useEditorBreadcrumbs'

export const BlockConfigurator = () => {
  useEditorBreadcrumbs()
  const { form, block: uuid = '' } = useEditorRouteParams()

  return !uuid ? (
    <Redirect to={`/editor/${form}/new`} />
  ) : (
    <div className='flex flex-col gap-6 my-8'>
      <div className='flex items-center space-x-2'>
        <BlockNameInput uuid={uuid} />
        <BlockDeleteButton uuid={uuid} />
      </div>
      <BlockTemplateToggle uuid={uuid} />
      <BlockChildrenList uuid={uuid} />
      <BlockConditionConfigurator uuid={uuid} />
    </div>
  )
}

export const BlockConditionConfigurator = ({ uuid }: { uuid: string }) => {
  const { block, setBlock } = useEditorBlock(uuid)

  return !block ? null : (
    <div>
      <h4>Blockkonditionen</h4>
      <ConditionConfigurator
        condition={block.condition || []}
        setCondition={(condition) => setBlock({ condition })}
      />
    </div>
  )
}
