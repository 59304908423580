import React, { FC } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { Block } from '../backbone/types'
import { useEditorState } from '../hooks/useEditorState'
import { BlockNavbarListItem } from './BlockNavbarListItem'

interface props {
  block: Block
  indent?: boolean
}

export const EditorNavbarListItem: FC<props> = ({
  block: { label, uuid, sites = [] },
  indent = false,
}) => {
  const form = useEditorState()
  const path = `/editor/${form.uuid}/${uuid}`

  const match = useRouteMatch(path)
  const isActive = !!match?.isExact

  return (
    <li className='flex flex-col mb-5 border-l-[3px]'>
      <Link
        title={label}
        className={`clickable block leading-9 text-font text-lg space-x-2 hover:text-accent hover:border-accent truncate 
            ${isActive && indent && 'text-accent '}
            ${
              isActive
                ? 'border-accent i-am-active-block'
                : 'i-am-inactive-block'
            }
            ${
              indent
                ? 'type-block indent-true'
                : 'indent-false type-page border-l-[3px]  ml-[-3px]'
            }`}
        to={path}>
        <span className='mx-2 ml-5 text-lg'>&#8226;</span>
        <span className='truncate'>{label}</span>
      </Link>
      <ul className='flex flex-col'>
        {sites.map((s, i) => (
          <BlockNavbarListItem parentPath={path} site={s} key={i} />
        ))}
      </ul>
    </li>
  )
}
