import { useFormik } from 'formik'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import * as Yup from 'yup'
import { login } from '../redux/auth'
import { RootState } from '../redux/store'
import { MaterialButton } from '../shared/MaterialButton'
import { TextInputCompact } from '../ui/TextInputCompact'
import { ErrorMessage } from './ErrorMessage'
import { useSearchParams } from './useSearchParams'

interface props {
  defaultRedirect?: string
}

export const Login: FC<props> = ({ defaultRedirect = '/' }) => {
  const dispatch = useDispatch()
  const error = useSelector((state: RootState) => state.auth.error)
  const history = useHistory()
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)

  const searchParams = useSearchParams()
  const customRedirect = searchParams['redirect']

  useEffect(() => {
    if (isLoggedIn) history.replace(customRedirect || defaultRedirect)
  }, [isLoggedIn, history, customRedirect, defaultRedirect])

  const formik = useFormik({
    initialValues: { mail: '', password: '' },
    onSubmit: (values) => {
      dispatch(login(values))
    },
    validationSchema: Yup.object({
      mail: Yup.string().required(),
      password: Yup.string().required(),
    }),
  })
  return (
    <div className=' bg-white fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center'>
      <form
        onSubmit={formik.handleSubmit}
        className='p-8 border-2 w-full mx-4  max-w-xl rounded-xl'>
        <h1 className='text-4xl text-center mb-10'>Anmeldung</h1>
        <TextInputCompact
          autoComplete='off'
          name='mail'
          value={formik.values.mail}
          onChange={formik.handleChange}
          label='Mail'
        />
        <TextInputCompact
          name='password'
          value={formik.values.password}
          onChange={formik.handleChange}
          type='password'
          label='Passwort'
        />
        <MaterialButton
          type='submit'
          className='bg-accent text-white w-full my-4'>
          Bestätigen
        </MaterialButton>
        <ErrorMessage
          error={
            (formik.touched.mail && formik.errors.mail) ||
            (formik.touched.password && formik.errors.password)
          }>
          Bitte füllen Sie alle Felder aus
        </ErrorMessage>
        <ErrorMessage error={error}>
          Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben.
        </ErrorMessage>
      </form>
    </div>
  )
}
