import { ControlledMenu } from '@szhsin/react-menu'
import React, { FC, MutableRefObject, useRef } from 'react'
import { useClickAwayListener } from '../backbone/useClickAwayListener'

export const ContextMenu: FC<{
  anchorRef: MutableRefObject<null>
  isOpen: boolean
  setIsOpen: (v: boolean) => void
}> = ({ anchorRef, isOpen, setIsOpen, children }) => {
  const menu = useRef(null)
  useClickAwayListener(menu, () => setIsOpen(false))
  return (
    <ControlledMenu
      anchorRef={anchorRef}
      state={isOpen ? 'open' : 'closed'}
      ref={menu}>
      {children}
    </ControlledMenu>
  )
}
