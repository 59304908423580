import React, { FC, useState } from 'react'
import { Input } from '../backbone/types'
import { EditorFieldWrapperHeader } from './EditorFieldWrapperHeader'
import { FieldConfigurator } from './FieldConfigurator'

interface props {
  input: Input
  reorder: (source: number, destination: number) => void
  saveInput: (input: Partial<Input>) => void
  removeInput: () => void
}

export const TAG = 'FIELD'
export const EditorFieldWrapper: FC<props> = ({
  reorder,
  input,
  removeInput,
  saveInput,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className='p-2 border rounded m-2' data-uuid={input.uuid}>
      <EditorFieldWrapperHeader
        setColumnWidth={(columns) => saveInput({ columns })}
        removeInput={removeInput}
        input={input}
        reorder={reorder}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setIsRequired={(isRequired) => saveInput({ isRequired })}
        setIsBranchingPoint={(isBranchingPoint) =>
          saveInput({ isBranchingPoint })
        }
        setGroup={(group) => saveInput({ group })}
      />
      <FieldConfigurator saveInput={saveInput} input={input} isOpen={isOpen} />
    </div>
  )
}
