import React, { FC } from 'react'
import { ProtectedRoute } from '../shared/ProtectedRoute'
import { EditorNavbar } from './EditorNavbar'
import { SidebarListItem } from './SidebarListItem'

interface props {
  className?: string
}

const formFinderLink = process.env.REACT_APP_FINDER_URL || '.'

const links = [
  {
    label: 'Formular-Finder',
    path: `${formFinderLink}/wp/wp-login.php`,
    external: true,
  },
  { label: 'Formulare', path: '/folder' },
  { label: 'Editor', path: '/editor', hideWhenInactive: true },
]

export const Sidebar: FC<props> = ({ className }) => {
  return (
    <ProtectedRoute path='/'>
      <div
        className={`flex flex-col w-80 pt-[109px] mb-[70px] overflow-auto ${className}`}>
        <h2 className='font-semibold'>Admin-Bereich</h2>
        <ul className='h-screen flex flex-col mb-[100px]'>
          {links.map((link, index) => (
            <SidebarListItem key={index} {...link} />
          ))}
          <ProtectedRoute path='/editor/:form'>
            <ul
              className='pl-6'
              onContextMenu={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}>
              <EditorNavbar />
            </ul>
          </ProtectedRoute>
        </ul>
      </div>
    </ProtectedRoute>
  )
}
