import { useQuery } from '@apollo/client'
import { useRouteMatch } from 'react-router-dom'
import { GET_FOLDER } from '../backbone/queries'
import { usePagination } from '../hooks/usePagination'
import { Folder, QueryFolderArgs } from './types'

export const useSubFolderItems = () => {
  const { setTotalItemCount, getPageItems } = usePagination(true)
  const match = useRouteMatch<{ uuid: string }>('/folder/:uuid')
  const uuid = match?.params.uuid || ''

  const query = useQuery<{ folder: Folder }, QueryFolderArgs>(GET_FOLDER, {
    variables: { uuid },
    onCompleted: (data) => {
      setTotalItemCount(data.folder.forms.length || 0)
    },
  })
  return {
    loading: query.loading,
    forms: getPageItems(query.data?.folder.forms),
  }
}
