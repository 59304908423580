import { useRouteMatch } from 'react-router-dom'

interface routeParams {
  form: string
  block?: string
  site?: string
}
export const useEditorRouteParams = () => {
  const match = useRouteMatch<routeParams>([
    '/editor/:form/:block/:site',
    '/editor/:form/:block',
    '/editor/:form',
  ])

  return match?.params || ({} as routeParams)
}
