import React, { FC } from 'react'
import { FolderItem } from './FolderItem'
import { props } from './FolderView'

export const FolderList: FC<props> = ({ folders }) =>
  !folders.length ? (
    <div className='text-s text-gray-400 mt-12 w-full flex justify-center'>
      Es sind noch keine Ordner vorhanden. Erstellen Sie Ihren ersten Ordner.
    </div>
  ) : (
    <>
      {folders.map((folder, index) => (
        <FolderItem key={index} folder={folder} />
      ))}
    </>
  )
