import React from 'react'
import { reorder } from '../backbone/utils'
import { useEditorBlock } from '../hooks/useEditorBlock'
import { useAppSelector } from '../redux/store'
import { ChildrenSortingList } from './ChildrenSortingList'

export const BlockChildrenList = ({ uuid }: { uuid: string }) => {
  const state = useAppSelector((state) => state.editor)
  const { block, setBlock } = useEditorBlock(uuid)
  const sites = block.sites || []
  const deleteBlock = (uuid: string) =>
    setBlock({ sites: sites.filter((s) => s.uuid !== uuid) })

  const sortBlocks = (source: number, target: number) =>
    setBlock({ sites: reorder(block.sites || [], source, target) })

  const items =
    sites.map((s, index) => ({
      ...s,
      position: index,
      linkPrefix: `/editor/${state?.uuid}/${block.uuid}`,
    })) || []

  return (
    <ChildrenSortingList
      title='Seiten in diesem Block'
      items={items}
      onDelete={deleteBlock}
      reorder={sortBlocks}
    />
  )
}
