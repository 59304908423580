import { FC, SVGProps } from 'react'

export const FolderIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width='42'
    height='40'
    viewBox='0 0 42 40'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M38.1818 39H3.81818C2.80554 39 1.83437 38.5996 1.11832 37.887C0.402272 37.1744 0 36.2078 0 35.2V4C0 2.34315 1.34315 1 3 1H13.6142C14.6463 1 15.6059 1.53052 16.1548 2.40454L19.1634 7.19546C19.7123 8.06948 20.6719 8.6 21.704 8.6H39C40.6568 8.6 42 9.94315 42 11.6V35.2C42 36.2078 41.5977 37.1744 40.8817 37.887C40.1656 38.5996 39.1945 39 38.1818 39Z'
      stroke='#495057'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
