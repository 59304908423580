import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SnackbarProps } from '../shared/Snackbar'

export type Sorting =
  | 'none'
  | 'date asc'
  | 'date desc'
  | 'title asc'
  | 'title desc'
interface State {
  isGridView: boolean
  snackbar: SnackbarProps
  sorting: Sorting
}
const initialState: State = { isGridView: true, snackbar: {}, sorting: 'none' }

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setGridView: (state, action: PayloadAction<boolean>) => {
      state.isGridView = action.payload
    },
    showSnackbar: (state, action: PayloadAction<SnackbarProps>) => {
      state.snackbar = action.payload
    },
    setSorting: (state, action: PayloadAction<Sorting>) => {
      state.sorting = action.payload
    },
  },
})

export const uiReducer = uiSlice.reducer

export const { showSnackbar, setGridView, setSorting } = uiSlice.actions
