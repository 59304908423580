import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 } from 'uuid'
import { labelToName } from '../backbone/labelToName'
import { Site } from '../backbone/types'
import { useEditorActions } from '../hooks/useEditorActions'
import { useEditorRouteParams } from '../hooks/useEditorRouteParams'

export const EditorNewSiteHandler = () => {
  const history = useHistory()
  const { form, block, site } = useEditorRouteParams()
  const { saveSite } = useEditorActions()
  const createAndRedirect = () => {
    if (!!block && site === 'new') {
      const newSite: Site = {
        uuid: v4(),
        inputs: [],
        label: 'Neue Seite',
        name: labelToName('Neue Seite'),
        position: 0,
      }
      const newSiteUrl = `/editor/${form}/${block}/${newSite.uuid}`
      saveSite(block, newSite)
      history.replace(newSiteUrl)
    }
  }
  useLayoutEffect(createAndRedirect)
  return null
}
