import { useEditorRouteParams } from './useEditorRouteParams'
import { useEditorSite } from './useEditorSite'

export const useFieldGroups = () => {
  const { block, site: siteUuid } = useEditorRouteParams()
  const { site } = useEditorSite(block!, siteUuid!)

  return [
    '',
    ...(Object.keys(
      (site?.inputs || [])
        .flatMap((i) => i.group)
        .map((g) => g as string)
        .filter((g) => !!g)
        .reduce((obj, key) => ({ ...obj, [key]: key }), {})
    ) || []),
  ]
}
