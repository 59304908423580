import { MenuItem } from '@szhsin/react-menu'
import { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from '../backbone/types'
import { MenuProps } from '../backbone/useContextMenuControls'
import { useDuplicateForm } from '../hooks/useDuplicateForm'
import { useMoveFormToFolder } from '../hooks/useMoveFormToFolder'
import { ContextMenu } from '../shared/ContextMenu'
import { MoveFormToFolderModal } from './MoveFormToFolderModal'
import { useDeleteForm } from './useDeleteForm'
import { useSetFormStatus } from '../hooks/useSetFormStatus'
import { useCopyFormLink } from '../hooks/useCopyFormLink'
import { DeleteItemModal } from './DeleteItemModal'

interface props extends MenuProps, Form {}
export const FormContextMenu: FC<props> = ({
  isDraft = false,
  label,
  uuid,
  isOpen,
  ...props
}) => {
  const history = useHistory()
  const { duplicate } = useDuplicateForm(uuid)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const { remove } = useDeleteForm(uuid)
  const menu = useMoveFormToFolder()
  const { setStatus } = useSetFormStatus(uuid)
  const copyLink = useCopyFormLink(uuid)

  const isContextMenuOpen = isOpen && !menu.open && !openDeleteDialog

  return (
    <>
      <MoveFormToFolderModal menu={menu} formUuid={uuid} formLabel={label} />
      <DeleteItemModal
        open={openDeleteDialog}
        remove={remove}
        setOpen={setOpenDeleteDialog}
      />
      <ContextMenu isOpen={isContextMenuOpen} {...props}>
        <MenuItem onClick={() => history.push(`/editor/${uuid}`)}>
          Bearbeiten
        </MenuItem>
        <MenuItem onClick={duplicate}>Duplizieren</MenuItem>
        <MenuItem onClick={() => menu.setOpen(true)}>Verschieben</MenuItem>
        {!isDraft && (
          <MenuItem onClick={() => setStatus(true)}>
            In Entwurf umwandeln
          </MenuItem>
        )}
        {!isDraft && (
          <MenuItem onClick={copyLink}>Klientenlink erzeugen</MenuItem>
        )}
        {isDraft && (
          <MenuItem onClick={() => setStatus(false)}>Veröffentlichen</MenuItem>
        )}
        <MenuItem onClick={() => setOpenDeleteDialog(true)}>Löschen</MenuItem>
      </ContextMenu>
    </>
  )
}
