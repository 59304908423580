import { FC } from 'react'
import { Statement } from '../backbone/types'
import { useEditorBlocks } from '../hooks/useEditorBlocks'
import { useEditorSelectFields } from '../hooks/useEditorSelectFieldsd'
import { MaterialSelect } from '../shared/MaterialSelect'

export const ConditionStatementForm: FC<
  Statement & { onChange(v: Partial<Statement>): void }
> = ({ operand, target, value, con, onChange }) => {
  const selectFields = useEditorSelectFields()
  const blocks = useEditorBlocks()
  const currentSelect = selectFields.find((s) => s.uuid === target)

  const isTargetOfTypeBlock = !!blocks.find((b) => b.uuid === target)
  return (
    <>
      <MaterialSelect
        value={target}
        className='!w-52'
        onChange={(e) => onChange({ target: e.currentTarget.value })}>
        <option value='' disabled>
          Feld/Block
        </option>
        {blocks.map((b) => (
          <option key={b.uuid} value={b.uuid}>
            Anzahl von {b.label}
          </option>
        ))}
        {selectFields.map((s) => (
          <option key={s.uuid} value={s.uuid}>
            {s.blockLabel} &gt; {s.label}
          </option>
        ))}
      </MaterialSelect>
      <MaterialSelect
        className='!w-32'
        value={operand}
        onChange={(e) => onChange({ operand: e.currentTarget.value })}>
        <option value='=='>ist gleich</option>
        <option value='!='>ist ungleich</option>
        {isTargetOfTypeBlock && <option value='>='>ist größer/gleich</option>}
        {isTargetOfTypeBlock && <option value='<='>ist kleiner/gleich</option>}
        {isTargetOfTypeBlock && <option value='<'>ist kleiner</option>}
        {isTargetOfTypeBlock && <option value='>'>ist größer</option>}
      </MaterialSelect>
      <MaterialSelect
        value={value}
        className='!w-52'
        onChange={(e) => onChange({ value: e.currentTarget.value })}>
        <option value='' disabled>
          Wert
        </option>
        {isTargetOfTypeBlock ? (
          <>
            <option value='0'>0</option>
            <option value='1'>1</option>
          </>
        ) : (
          currentSelect?.options.map((o) => (
            <option value={o!.uuid}>{o!.label}</option>
          ))
        )}
      </MaterialSelect>
      {!!con && (
        <MaterialSelect
          value={con}
          className='!w-24'
          onChange={(e) => onChange({ con: e.currentTarget.value })}>
          <option value='&&'>UND</option>
          <option value='||'>ODER</option>
        </MaterialSelect>
      )}
    </>
  )
}
