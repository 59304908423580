import { FC, SVGProps } from 'react'

export const QuestionCircle: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-labelledby='n-icon-help'
    role='img'
    {...props}>
    <title 
    id="n-icon-help"
    >
    Icon Help
    </title>
    <path
      d='M7.21244 12.725H8.78744V11.15H7.21244V12.725ZM7.99994 0.125C3.65294 0.125 0.124939 3.653 0.124939 8C0.124939 12.347 3.65294 15.875 7.99994 15.875C12.3469 15.875 15.8749 12.347 15.8749 8C15.8749 3.653 12.3469 0.125 7.99994 0.125ZM7.99994 14.3C4.52706 14.3 1.69994 11.4729 1.69994 8C1.69994 4.52713 4.52706 1.7 7.99994 1.7C11.4728 1.7 14.2999 4.52713 14.2999 8C14.2999 11.4729 11.4728 14.3 7.99994 14.3ZM7.99994 3.275C6.25956 3.275 4.84994 4.68463 4.84994 6.425H6.42494C6.42494 5.55875 7.13369 4.85 7.99994 4.85C8.86619 4.85 9.57494 5.55875 9.57494 6.425C9.57494 8 7.21244 7.80313 7.21244 10.3625H8.78744C8.78744 8.59062 11.1499 8.39375 11.1499 6.425C11.1499 4.68463 9.74031 3.275 7.99994 3.275Z'
      fill='#495057'
    />
  </svg>
)