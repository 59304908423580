import React, { FC } from 'react'
import { Folder } from '../backbone/types'
import { useFolderItemSorting } from '../hooks/useFolderItemSorting'
import { FolderViewHeader } from './FolderViewHeader'
import { useGridViewToggle } from '../backbone/useGridViewToggle'
import { FolderList } from './FolderList'

export interface props {
  folders: Folder[]
}

export const FolderView: FC<props> = ({ folders = [] }) => {
  const { sortingFunction } = useFolderItemSorting()
  const { isGridView } = useGridViewToggle()

  return (
    <>
      <FolderViewHeader />
      <hr />
      <div
        className={`flex py-2 grid-cols-2
        ${isGridView ? 'flex-wrap gap-5' : 'flex-wrap flex-row'}
        `}>
        <FolderList folders={folders.slice().sort(sortingFunction)} />
      </div>
    </>
  )
}
