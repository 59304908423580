import { useCallback, useEffect } from 'react'

export const useClickAwayListener = (ref: any, callback: any) => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    },
    [ref, callback]
  )
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [ref, handleClickOutside])
}
