import { useDispatch } from 'react-redux'
import { useAppSelector } from '../redux/store'
import { setGridView } from '../redux/ui'

export const useGridViewToggle = () => {
  const dispatch = useDispatch()
  const isGridView = useAppSelector((state) => state.ui.isGridView)

  const setIsGridView = (v: boolean) => {
    dispatch(setGridView(v))
  }

  return { isGridView, setIsGridView }
}
