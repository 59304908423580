import React, { FC } from 'react'
import { EditorSaveButton } from '../editor/EditorSaveButton'
import { PaginationView } from './PaginationView'

interface props {
  className?: string
}

export const Footer: FC<props> = ({ className }) => {
  return (
    <div
      className={`${className} border-t border-gray-200 h-16 bg-white fixed bottom-0 z-10`}>
      <footer className='max-w-8xl mx-auto h-full flex items-center px-4 w-full justify-between'>
        <div className='inline-block px-3 py-2.5 w-80 flex justify-start items-center'>
          &nbsp;
        </div>
        <PaginationView />
        <EditorSaveButton />
      </footer>
    </div>
  )
}