import React from 'react'
import { useAllForms } from '../backbone/useAllForms'
import { FormItem } from '../folder/FormItem'
import { Button } from '../ui/Button'
import { LoadingIndicator } from '../ui/LoadingIndicator'
import { useGridViewToggle } from '../backbone/useGridViewToggle'

export const SearchResultList = ({
  search,
  clearSearch,
}: {
  search: string
  clearSearch: () => void
}) => {
  const { data, loading } = useAllForms()
  const { isGridView } = useGridViewToggle()
  return loading ? (
    <LoadingIndicator />
  ) : (
    <div className='w-full'>
      <h2 className='text-xl my-4'>Suchergebnisse für: {search}</h2>
      <div 
        className={`flex flex-wrap my-2
        ${isGridView ? 'gap-5' : 'gap-0'}
        `}
      >
        {data?.allFolders
          .flatMap((f) => f.forms)
          .filter((f) => f.label.toLowerCase().match(search.toLowerCase()))
          .map((f, i) => (
            <FormItem form={f} key={i} />
          ))}
      </div>
      <Button
        onClick={clearSearch}
        className='!text-white !bg-accent mx-auto mt-20 max-w-xs'>
        Suche verlassen
      </Button>
    </div>
  )
}
