import React, { FC } from 'react'
import { Modal } from '../shared/Modal'

interface props {
  open: boolean
  setOpen: (v: boolean) => void
  remove: () => void
}

export const DeleteItemModal: FC<props> = ({ remove, open, setOpen }) => {
  const deleteItem = () => {
    remove()
    setOpen(false)
  }
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      submit={{ label: 'Löschen', action: deleteItem }}
      buttons={[{ label: 'Abbrechen', action: () => setOpen(false) }]}
      title='Formular verschieben'>
      <div className='prose pb-8'>
        <h2>Sie sind dabei, dieses Objekt zu löschen.</h2>
        <p>
          Wenn Sie das Objekt nicht löschen wollen, klicken Sie auf Abbrechen!
        </p>
      </div>
    </Modal>
  )
}
