import { useMemo } from 'react'
import useClipboard from 'react-use-clipboard'
import { useSnackbar } from './useSnackbar'
import { InfoIcon } from '../icons/InfoIcon'

export const useCopyFormLink = (uuid: string) => {
  const title = useMemo(
    () => (
      <div className='flex items-center space-x-1'>
        <InfoIcon />
        <span>Erfolg</span>
      </div>
    ),
    []
  )
  const { show: showSnackbar } = useSnackbar({
    title,
    text: 'Der Formularlink wurde in die Zwischenablage kopiert.',
  })
  const formLink = `${process.env.REACT_APP_CLIENT_URL}/${uuid}`
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [_, setCopied] = useClipboard(formLink)

  const copyLink = () => {
    setCopied()
    showSnackbar()
  }

  return copyLink
}
