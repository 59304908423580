import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useEditorBlock } from '../hooks/useEditorBlock'
import { useSnackbar } from '../hooks/useSnackbar'
import { TrashIcon } from '../icons/TrashIcon'
import { Modal } from '../shared/Modal'

export const BlockDeleteButton: FC<{ uuid: string }> = ({ uuid }) => {
  const history = useHistory()
  const { block } = useEditorBlock(uuid)
  const { show: showSnackbar } = useSnackbar({
    title: '\u24D8',
    text: `Der Formularblock ${block.label} wurde gelöscht.`,
  })
  const { remove } = useEditorBlock(uuid)
  const [showModal, setShowModal] = useState(false)
  const deleteBlock = () => {
    remove()
    showSnackbar()
    history.replace('/editor/')
  }

  return (
    <>
      <TrashIcon
        className='clickable fill-current activ:text-accent'
        onClick={() => setShowModal(true)}
      />
      <Modal
        open={showModal}
        setOpen={setShowModal}
        submit={{ label: 'Löschen', action: deleteBlock }}
        buttons={[{ label: 'Abbrechen', action: () => setShowModal(false) }]}
        title='Formularblock löschen'
      >
        Der Formularblock „Verkäufer“ wird unwiderruflich gelöscht.
      </Modal>
    </>
  )
}
