import React, { useMemo, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { v4 } from 'uuid'
import { MaterialRadioButton } from '../shared/MaterialRadioButton'
import { BlockNameInput } from './BlockNameInput'
import { NewBlockSaveButton } from './NewBlockSaveButton'
import { TemplateBlockSelector } from './TemplateBlockSelector'
import { useEditorState } from '../hooks/useEditorState'

export const NewBlockHandler = () => {
  const [createEmpty, setCreateEmpty] = useState(true)
  const { uuid: form = '' } = useEditorState()
  const uuid = useMemo(() => v4(), [])

  return !form ? (
    <Redirect to='/editor' />
  ) : (
    <div className='flex flex-col gap-6 my-8'>
      <MaterialRadioButton
        checked={createEmpty}
        onClick={() => setCreateEmpty(true)}>
        Leeren Formularblock erstellen
      </MaterialRadioButton>
      {createEmpty && (
        <form
          onSubmit={(e) => e.preventDefault()}
          className='pl-8 py-4 flex items-center space-x-1'>
          <BlockNameInput uuid={uuid} />
          <NewBlockSaveButton uuid={uuid} />
        </form>
      )}
      <MaterialRadioButton
        checked={!createEmpty}
        onClick={() => setCreateEmpty(false)}>
        Vorlage auswählen
      </MaterialRadioButton>
      {!createEmpty && <TemplateBlockSelector />}
    </div>
  )
}
