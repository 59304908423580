import { Input } from '../backbone/types'
import { useEditorRouteParams } from '../hooks/useEditorRouteParams'
import { useEditorSite } from '../hooks/useEditorSite'
import { useFieldGroups } from '../hooks/useFieldGroups'
import { EditorFieldGroup } from './EditorFieldGroup'

export const SiteConfiguratorFieldList = () => {
  const { block = '', site: siteUuid = '' } = useEditorRouteParams()
  const { site, reorderFields, save } = useEditorSite(block, siteUuid)
  const groups = useFieldGroups()

  if (!block || !siteUuid) return null
  const fields = site?.inputs || []

  const removeField = (uuid: string) => () =>
    save?.({ inputs: fields.filter((f) => f.uuid !== uuid) })

  const saveField = (uuid: string) => (input: Partial<Input>) =>
    save?.({
      inputs: fields.map((f) =>
        f.uuid !== uuid ? f : ({ ...f, ...input } as Input)
      ),
    })

  return (
    <>
      {groups.map((g) => (
        <EditorFieldGroup
          fields={fields.filter((f) => (!!g ? f.group === g : !f.group))}
          group={g}
          key={g}
          remove={removeField}
          reorder={reorderFields || (() => {})}
          save={saveField}
        />
      ))}
    </>
  )
}
