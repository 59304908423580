import { MenuItem } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { labelToName } from '../backbone/labelToName'
import { Folder } from '../backbone/types'
import { useContextMenuControls } from '../backbone/useContextMenuControls'
import { useGridViewToggle } from '../backbone/useGridViewToggle'
import { parseTimestampToLocalDateString } from '../backbone/utils'
import { FolderIcon } from '../icons/FolderIcon'
import { ContextMenu } from '../shared/ContextMenu'
import { DeleteItemModal } from './DeleteItemModal'
import { useDeleteFolder } from './useDeleteFolder'
import { useUpdateFolder } from './useUpdateFolder'

export const FolderItem: FC<{ folder: Folder }> = ({ folder }) => {
  const { openContextMenu, setIsOpen, isOpen, ...props } =
    useContextMenuControls()
  const { isGridView } = useGridViewToggle()
  const { update } = useUpdateFolder(folder.uuid)
  const { remove } = useDeleteFolder(folder.uuid)
  const [isEditing, setIsEditing] = useState(false)
  const [input, setInput] = useState(folder.label)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const onEdit = () => {
    update({ label: input, name: labelToName(input) }).then(() =>
      setIsEditing(false)
    )
  }

  const isContextOpen = isOpen && !openDeleteDialog

  return (
    <>
      <DeleteItemModal
        open={openDeleteDialog}
        remove={remove}
        setOpen={setOpenDeleteDialog}
      />
      <Link
        ref={props.anchorRef}
        to={`/folder/${folder.uuid}`}
        onClick={(e) => isEditing && e.preventDefault()}
        onFocus={(e) => isEditing && e.preventDefault()}
        onContextMenu={openContextMenu}
        className={`flex items-center gap-2 border-2 border-transparent
        ${
          isGridView
            ? 'flex-col py-4 w-[126px]'
            : 'border-b-[1px] border-gray-300 w-full my-0 py-4'
        }
        ${(isEditing || isOpen) && 'rounded !border-accent'}
        `}>
        <FolderIcon className={`fill-current ${!isGridView && 'h-10 mr-4'}`} />
        {isEditing ? (
          <input
            type='text'
            value={input}
            onFocus={() => setIsOpen(false)}
            onBlur={onEdit}
            autoFocus
            className='text-center border-0 py-0 !w-36 !ring-0 border-b-2 !border-accent'
            onChange={(e) => setInput(e.currentTarget.value)}
          />
        ) : (
          <h2 className='text-center w-full break-words hyphen w-[126px]'>
            {folder.label}
          </h2>
        )}
        {!isGridView && (
          <div className='!ml-auto'>
            {parseTimestampToLocalDateString(folder.lastUpdated)}
          </div>
        )}
      </Link>
      <ContextMenu {...props} isOpen={isContextOpen} setIsOpen={setIsOpen}>
        <MenuItem onClick={() => setIsEditing(true)}>Umbenennen</MenuItem>
        <MenuItem onClick={() => setOpenDeleteDialog(true)}>Löschen</MenuItem>
      </ContextMenu>
    </>
  )
}
