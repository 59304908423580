import { FC, SVGProps } from 'react'

export const Logo: FC<SVGProps<SVGElement>> = () => (
  <svg
    width='130'
    height='33'
    viewBox='0 0 130 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path 
      d='M0 7.22647C0 3.2354 3.2354 0 7.22647 0H25.5335C29.5246 0 32.76 3.2354 32.76 7.22647V25.5335C32.76 29.5246 29.5246 32.76 25.5335 32.76H7.22647C3.2354 32.76 0 29.5246 0 25.5335V7.22647Z' 
      fill='#119CBB'
    />
    <path 
      fillRule='evenodd' 
      clipRule='evenodd' 
      d='M26.2834 5.99099C26.4349 6.1414 26.5122 6.35113 26.4945 6.56391C26.0855 11.4711 23.0234 15.2893 19.4853 18.0405C15.9433 20.7948 11.8336 22.5541 9.11248 23.3375C8.92432 23.3916 8.72224 23.3671 8.55248 23.2696C8.38272 23.172 8.25981 23.0097 8.21189 22.8199C7.38344 19.5379 7.90525 16.7974 9.23142 14.5539C10.5444 12.3326 12.6156 10.6478 14.8158 9.385C19.2044 6.86615 24.3102 5.91138 25.7089 5.78422C25.9215 5.76489 26.1318 5.84058 26.2834 5.99099ZM9.44897 21.7225C8.96229 19.1433 9.44513 17.0326 10.4756 15.2893C11.6166 13.3591 13.4597 11.8297 15.5352 10.6385C19.0113 8.64341 22.9953 7.67834 24.9433 7.34804C24.3063 11.2733 21.7357 14.4598 18.5981 16.8996C15.5336 19.2825 12.0027 20.8918 9.44897 21.7225Z' 
      fill='white'
    />
    <path 
      d='M7.22656 26.0154C13.432 16.0187 16.3757 14.2924 20.2342 11.3215C16.0336 11.996 12.3989 15.537 10.8466 17.8254C9.45371 19.8786 8.05259 23.8812 7.22656 26.0154Z' 
      fill='white'
    />
    <path 
      fillRule='evenodd' 
      clipRule='evenodd' 
      d='M20.3446 11.2738C20.3665 11.3244 20.3512 11.3834 20.3075 11.417C19.9713 11.6759 19.6423 11.9251 19.3179 12.1709C17.7769 13.3383 16.3375 14.4288 14.6967 16.1177C12.7109 18.1619 10.4288 21.0849 7.32875 26.0789C7.29596 26.1317 7.22834 26.1509 7.17269 26.1232C7.11704 26.0954 7.09166 26.0299 7.1141 25.9719C7.23915 25.6488 7.37775 25.2819 7.52774 24.8848C7.87151 23.9747 8.27512 22.9062 8.71249 21.8442C9.34038 20.3195 10.0428 18.7955 10.7468 17.7578C11.5301 16.603 12.8357 15.1363 14.4736 13.8729C16.1112 12.6097 18.0884 11.5441 20.215 11.2026C20.2694 11.1939 20.3228 11.2232 20.3446 11.2738ZM14.6207 14.0636C13.0031 15.3114 11.7151 16.7594 10.9461 17.893C10.2572 18.9085 9.56263 20.4124 8.93523 21.9359C8.50671 22.9765 8.11183 24.0203 7.77249 24.9186C10.5531 20.537 12.6639 17.8647 14.524 15.9499C16.1784 14.2468 17.6337 13.1444 19.1758 11.9763C19.3687 11.8302 19.5629 11.683 19.7591 11.5335C17.8713 11.9412 16.1095 12.9152 14.6207 14.0636Z' 
      fill='white'
    />
    <path 
      d='M14.5952 24.2831C11.9219 24.1897 9.35237 25.3855 8.40174 25.995C11.4878 24.4868 14.1008 24.6025 15.0215 24.8489C20.094 26.4952 22.1213 25.9232 23.3754 25.1406C18.7766 25.8982 16.9311 24.4565 14.5952 24.2831Z' fill='white'/>
    <path 
      fillRule='evenodd' 
      clipRule='evenodd' 
      d='M14.6434 24.5286C14.8147 24.5535 14.9623 24.5836 15.0839 24.6162C15.088 24.6173 15.092 24.6185 15.096 24.6198C18.2601 25.6467 20.2039 25.7963 21.5016 25.585C20.1283 25.6487 19.0362 25.502 18.0859 25.2964C17.6092 25.1932 17.1649 25.0744 16.7451 24.962C16.5749 24.9164 16.4086 24.872 16.2459 24.83C15.6989 24.6891 15.1819 24.5743 14.6434 24.5286ZM8.27189 25.7923C9.24754 25.1667 11.8654 23.9467 14.6038 24.0423C14.6069 24.0425 14.61 24.0426 14.6132 24.0429C15.2188 24.0878 15.7887 24.2147 16.3661 24.3635C16.5373 24.4076 16.7084 24.4534 16.8813 24.4997C17.2968 24.6109 17.7231 24.725 18.1878 24.8255C19.4902 25.1074 21.0706 25.2762 23.3364 24.9029C23.4497 24.8843 23.5604 24.9482 23.601 25.0556C23.6415 25.1631 23.6005 25.2842 23.5031 25.345C22.1636 26.1809 20.0539 26.7343 14.9535 25.08C14.0879 24.8509 11.5391 24.7299 8.50769 26.2115C8.39302 26.2675 8.25454 26.2244 8.19197 26.1131C8.1294 26.0019 8.16445 25.8612 8.27189 25.7923Z' 
      fill='white'
    />
    <path 
      d='M47.9305 28.4248V9.21655H41.3408V4.30934H60.6542V9.21655H54.0295V28.4248H47.9305Z' 
      fill='#119CBB'
    />
    <path 
      d='M74.0669 28.7052C72.034 28.7052 70.3632 28.5883 69.0546 28.3547C67.7694 28.0976 66.7646 27.7004 66.0402 27.1629C65.3158 26.6021 64.7783 25.8543 64.4278 24.9196C64.1006 23.9615 63.8903 22.7815 63.7969 21.3794C63.7268 19.9774 63.6917 18.3066 63.6917 16.3671C63.6917 14.4275 63.7268 12.7568 63.7969 11.3547C63.8903 9.95263 64.1006 8.78425 64.4278 7.84954C64.7783 6.89147 65.3158 6.1437 66.0402 5.60625C66.7646 5.04542 67.7694 4.64817 69.0546 4.4145C70.3632 4.15745 72.034 4.02893 74.0669 4.02893C76.0766 4.02893 77.724 4.15745 79.0092 4.4145C80.3178 4.64817 81.346 5.04542 82.0937 5.60625C82.8415 6.1437 83.379 6.89147 83.7061 7.84954C84.0332 8.78425 84.2436 9.95263 84.337 11.3547C84.4305 12.7568 84.4772 14.4275 84.4772 16.3671C84.4772 18.3066 84.4305 19.9774 84.337 21.3794C84.2436 22.7815 84.0332 23.9615 83.7061 24.9196C83.379 25.8543 82.8415 26.6021 82.0937 27.1629C81.346 27.7004 80.3178 28.0976 79.0092 28.3547C77.724 28.5883 76.0766 28.7052 74.0669 28.7052ZM74.0669 24.0083C74.9082 24.0083 75.5858 23.9849 76.0999 23.9382C76.6374 23.8681 77.058 23.7162 77.3618 23.4825C77.6656 23.2255 77.8876 22.8282 78.0278 22.2908C78.168 21.7533 78.2498 21.0055 78.2731 20.0475C78.3199 19.0894 78.3432 17.8626 78.3432 16.3671C78.3432 14.8715 78.3199 13.6447 78.2731 12.6866C78.2498 11.7286 78.168 10.9808 78.0278 10.4434C77.8876 9.9059 77.6656 9.52033 77.3618 9.28665C77.058 9.02961 76.6374 8.87772 76.0999 8.83099C75.5858 8.76088 74.9082 8.72583 74.0669 8.72583C73.2491 8.72583 72.5714 8.76088 72.034 8.83099C71.4965 8.87772 71.0759 9.02961 70.7721 9.28665C70.4683 9.52033 70.2463 9.9059 70.1061 10.4434C69.9659 10.9808 69.8725 11.7286 69.8257 12.6866C69.8023 13.6447 69.7907 14.8715 69.7907 16.3671C69.7907 17.8626 69.8023 19.0894 69.8257 20.0475C69.8725 21.0055 69.9659 21.7533 70.1061 22.2908C70.2463 22.8282 70.4683 23.2255 70.7721 23.4825C71.0759 23.7162 71.4965 23.8681 72.034 23.9382C72.5714 23.9849 73.2491 24.0083 74.0669 24.0083Z' 
      fill='#119CBB'
    />
    <path 
      d='M99.1943 28.7052C97.1613 28.7052 95.4905 28.5883 94.1819 28.3547C92.8967 28.0976 91.8919 27.7004 91.1675 27.1629C90.4431 26.6021 89.9056 25.8543 89.5551 24.9196C89.228 23.9615 89.0177 22.7815 88.9242 21.3794C88.8541 19.9774 88.8191 18.3066 88.8191 16.3671C88.8191 14.4275 88.8541 12.7568 88.9242 11.3547C89.0177 9.95263 89.228 8.78425 89.5551 7.84954C89.9056 6.89147 90.4431 6.1437 91.1675 5.60625C91.8919 5.04542 92.8967 4.64817 94.1819 4.4145C95.4905 4.15745 97.1613 4.02893 99.1943 4.02893C101.204 4.02893 102.851 4.15745 104.137 4.4145C105.445 4.64817 106.473 5.04542 107.221 5.60625C107.969 6.1437 108.506 6.89147 108.833 7.84954C109.161 8.78425 109.371 9.95263 109.464 11.3547C109.558 12.7568 109.605 14.4275 109.605 16.3671C109.605 18.3066 109.558 19.9774 109.464 21.3794C109.371 22.7815 109.161 23.9615 108.833 24.9196C108.506 25.8543 107.969 26.6021 107.221 27.1629C106.473 27.7004 105.445 28.0976 104.137 28.3547C102.851 28.5883 101.204 28.7052 99.1943 28.7052ZM99.1943 24.0083C100.036 24.0083 100.713 23.9849 101.227 23.9382C101.765 23.8681 102.185 23.7162 102.489 23.4825C102.793 23.2255 103.015 22.8282 103.155 22.2908C103.295 21.7533 103.377 21.0055 103.4 20.0475C103.447 19.0894 103.471 17.8626 103.471 16.3671C103.471 14.8715 103.447 13.6447 103.4 12.6866C103.377 11.7286 103.295 10.9808 103.155 10.4434C103.015 9.9059 102.793 9.52033 102.489 9.28665C102.185 9.02961 101.765 8.87772 101.227 8.83099C100.713 8.76088 100.036 8.72583 99.1943 8.72583C98.3764 8.72583 97.6988 8.76088 97.1613 8.83099C96.6238 8.87772 96.2032 9.02961 95.8995 9.28665C95.5957 9.52033 95.3737 9.9059 95.2335 10.4434C95.0933 10.9808 94.9998 11.7286 94.9531 12.6866C94.9297 13.6447 94.918 14.8715 94.918 16.3671C94.918 17.8626 94.9297 19.0894 94.9531 20.0475C94.9998 21.0055 95.0933 21.7533 95.2335 22.2908C95.3737 22.8282 95.5957 23.2255 95.8995 23.4825C96.2032 23.7162 96.6238 23.8681 97.1613 23.9382C97.6988 23.9849 98.3764 24.0083 99.1943 24.0083Z' 
      fill='#119CBB'
    />
    <path 
      d='M114.507 28.4248V4.30934H120.606V23.5176H130V28.4248H114.507Z' 
      fill='#119CBB'
    />
  </svg>
)
