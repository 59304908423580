import { FC } from 'react'
import { Link } from 'react-router-dom'

export const BreadcrumbItem: FC<{ path: string; label: string }> = ({
  label,
  path,
}) => {
  return (
    <>
      <span className='px-1'>&gt;</span>
      <Link className='last:font-bold' to={path}>
        {label}
      </Link>
    </>
  )
}
