import { FC } from 'react'
import { ChildItem } from './ChildItem'

export interface Item {
  label: string
  position: number
  uuid: string
  linkPrefix: string
}
export interface baseProps {
  reorder: (source: number, target: number) => void
  onDelete: (uuid: string) => void
}
interface props extends baseProps {
  items: Item[]
  title: string
}

export const ChildrenSortingList: FC<props> = ({ items, title, ...props }) => {
  return !items?.length ? null : (
    <div>
      <h3 className='my-4'>{title}</h3>
      <div className='space-y-2'>
        {items.map((item, index) => (
          <ChildItem item={item} key={index} {...props} />
        ))}
      </div>
    </div>
  )
}
