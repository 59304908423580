import { useEffect } from 'react'
import { useBreadcrumbs } from '../hooks/useBreadcrumbs'
import { useEditorRouteParams } from '../hooks/useEditorRouteParams'
import { useEditorState } from '../hooks/useEditorState'

export const useEditorBreadcrumbs = () => {
  const { form = '', block = '', site = '' } = useEditorRouteParams()
  const { blocks = [], label, uuid } = useEditorState()

  const { setBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    const navItems = [
      { label: label, uuid: uuid },
      ...blocks.flatMap((b) => ({ label: b.label, uuid: b.uuid })),
      ...blocks.flatMap((b) =>
        b.sites.flatMap((s) => ({ label: s.label, uuid: s.uuid }))
      ),
    ].flat()
    const dynamicBreadCrumbs = [form, block, site]
      .map((uuid) => navItems.find((i) => i.uuid === uuid)!)
      .filter((s) => !!s)
      .map(({ label, uuid }) => ({ label, path: uuid }))
    const breadcrumbs = [
      { label: 'Admin-Bereich', path: '/' },
      { label: 'Editor', path: '/editor' },
      ...dynamicBreadCrumbs.map((b, i) => ({
        label: b.label,
        path:
          '/editor/' +
          dynamicBreadCrumbs
            .slice(0, i + 1)
            .map((b) => b.path)
            .join('/'),
      })),
    ]
    setBreadcrumbs(breadcrumbs)
  }, [form, block, site, setBreadcrumbs, label, uuid, blocks])
}
