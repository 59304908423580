import React, { FC } from 'react'
import { v4 } from 'uuid'
import { Option } from '../backbone/types'
import { MaterialButton } from '../shared/MaterialButton'
import { FieldConfiguratorOptionInput } from './FieldConfiguratorOptionInput'

interface Props {
  isBranchingPoint: boolean
  options: Option[]
  setOptions: (values: Option[]) => void
}

export const FieldConfiguratorOptionListInput: FC<Props> = ({
  options,
  setOptions,
  isBranchingPoint,
}) => {
  return (
    <div className='my-8'>
      {options.map((option, i) => (
        <FieldConfiguratorOptionInput
          isBranchingPoint={isBranchingPoint}
          remove={() =>
            setOptions(options.filter((item) => item.uuid !== option.uuid))
          }
          value={option}
          onChange={(o) =>
            setOptions(
              options.map((item) =>
                item.uuid !== option.uuid ? item : { ...item, ...o }
              )
            )
          }
          label={`Option ${i + 1}`}
          key={i}
        />
      ))}
      <MaterialButton
        onClick={() => setOptions([...options, { uuid: v4() } as Option])}>
        + Neue Option hinzufügen
      </MaterialButton>
    </div>
  )
}
