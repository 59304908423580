import { useHistory } from 'react-router-dom'
import { v4 } from 'uuid'
import { labelToName } from '../backbone/labelToName'
import { Form, Maybe } from '../backbone/types'
import { useEditorActions } from '../hooks/useEditorActions'
import { useAppSelector } from '../redux/store'
import { MaterialButton } from '../shared/MaterialButton'
import { MaterialInput } from '../shared/MaterialInput'
import { emptyFormTemplate } from './emptyFormTemplate'
import { useEditorBreadcrumbs } from './useEditorBreadcrumbs'

export const FormConfigurator = () => {
  useEditorBreadcrumbs()
  const state: Maybe<Form> = useAppSelector((state) => state.editor)
  const { saveForm } = useEditorActions()
  const history = useHistory()
  const onChange = (label: string) => {
    saveForm({ label, name: labelToName(label) })
  }
  const onSave = () => {
    const firstBlock = state?.blocks?.find((b) => !!b)
    const firstSite = firstBlock?.sites?.find((s) => !!s)
    const blockUuid = firstBlock?.uuid || v4()
    const siteUuid = firstSite?.uuid || v4()

    if (!state.blocks || !state.blocks.length) {
      saveForm(emptyFormTemplate(blockUuid, siteUuid))
    }
    history.push(`/editor/${state?.uuid}/${blockUuid}/${siteUuid}`)
  }
  return (
    <div className='flex flex-col gap-6 my-8'>
      <div className='flex items-center space-x-2'>
        <MaterialInput
          label='Titel:'
          value={state?.label || ''}
          onChange={onChange}
        />
        <MaterialButton onClick={onSave}>speichern</MaterialButton>
      </div>
    </div>
  )
}
