import { FC, useState } from 'react'
import { Option } from '../backbone/types'
import { useEditorState } from '../hooks/useEditorState'
import { TrashIcon } from '../icons/TrashIcon'
import { ChainIcon } from '../icons/ChainIcon'
import { OptionLabelInput } from './OptionLabelInput'
import { RichTextInput } from './RichTextInput'
import { SiteLinkInput } from './SiteLinkInput'
import { ConditionConfigurator } from './ConditionConfigurator'

interface props {
  label: string
  isBranchingPoint: boolean
  className?: string
  value: Partial<Option>
  onChange: (v: Partial<Option>) => void
  remove: () => void
}

export const FieldConfiguratorOptionInput: FC<props> = ({
  label,
  value,
  remove,
  isBranchingPoint,
  onChange,
}) => {
  const inputClasses =
    'form-control items-center w-full  bg-white  bg-clip-padding border-gray-300 transition ease-in-out m-0 p-0 focus:text-dark focus:bg-white focus:border-accent focus:outline-none shadow-md flex flex-wrap overflow-hidden border border-solid border-gray-300 rounded'
  const sites = useEditorState()?.blocks?.flatMap((b) =>
    b.sites.map((s) => ({ ...s, parent: b }))
  )

  const [openLinkSelector, setOpenLinkSelector] = useState(!!value)
  const removeLink = () => {
    onChange({ siteLinkUuid: null })
    setOpenLinkSelector(false)
  }
  return (
    <div className='flex items-baseline space-x-2 w-full my-6'>
      <label
        htmlFor='exampleFormControlInput1'
        className='form-label inline-block text-dark w-1/6'>
        {label}
      </label>
      <div className='w-full'>
        <div className={inputClasses}>
          <OptionLabelInput value={value} onChange={onChange} />
          {!!isBranchingPoint && (
            <ChainIcon
              onClick={() => setOpenLinkSelector(!openLinkSelector)}
              className={`h-[36px] w-10 px-[12px] text-font m-0 fill-current hover:cursor-pointer ${
                openLinkSelector ? 'bg-accent !text-white' : 'bg-gray-300'
              }`}
            />
          )}
          {!!isBranchingPoint && openLinkSelector && (
            <SiteLinkInput
              onRemove={removeLink}
              value={value}
              onChange={onChange}
              sites={sites || []}
            />
          )}
        </div>
        {!!isBranchingPoint && (
          <label className='mt-4 block'>Hinweistext (am Feld)</label>
        )}
        {!!isBranchingPoint && (
          <RichTextInput
            className='!mt-0'
            onChange={(helpText) => onChange({ helpText })}
            value={value.helpText || ''}
          />
        )}
        {!!isBranchingPoint && (
          <ConditionConfigurator
            condition={value.condition || []}
            setCondition={(condition) => onChange({ condition })}
          />
        )}
      </div>
      <TrashIcon onClick={remove} />
    </div>
  )
}
