import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PaginationState {
  totalItemCount: number
  page: number
  maxPage: number
}

const defaultState: PaginationState = {
  maxPage: 0,
  page: 0,
  totalItemCount: 0,
}

const initialState = {
  folders: defaultState,
  forms: defaultState,
} as {
  folders: PaginationState
  forms: PaginationState
}

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setFoldersPaginationState: (
      state,
      action: PayloadAction<Partial<PaginationState>>
    ) => ({
      ...state,
      folders: {
        ...state.folders,
        ...action.payload,
      },
    }),
    setFormsPaginationState: (
      state,
      action: PayloadAction<Partial<PaginationState>>
    ) => ({
      ...state,
      forms: {
        ...state.forms,
        ...action.payload,
      },
    }),
  },
})

export const {
  reducer: paginationReducer,
  actions: { setFoldersPaginationState, setFormsPaginationState },
} = paginationSlice
