import React from 'react'
import { Content } from './layout/Content'
import { Footer } from './layout/Footer'
import { Header } from './layout/Header'
import { Sidebar } from './layout/Sidebar'
import { Snackbar } from './shared/Snackbar'

function App() {
  return (
    <div
      id='root'
      className='overflow-auto p-0 flex flex-col flex-wrap relative content-center'>
      <Header className='h-16 w-full bg-white shadow-md' />
      <div className='max-w-8xl px-4 mx-auto flex-1 flex flex-nowrap w-full'>
        <Sidebar className='bg-white' />
        <Content className='flex-1' />
      </div>
      <Footer className='w-full mx-auto' />
      <Snackbar />
    </div>
  )
}

export default App
