import { FC } from 'react'
import { Block, Option, Site } from '../backbone/types'
import { TrashIcon } from '../icons/TrashIcon'
import { MaterialSelect } from '../shared/MaterialSelect'

export const SiteLinkInput: FC<{
  value: Partial<Option>
  onRemove: () => void
  onChange: (v: Partial<Option>) => void
  sites: (Site & { parent: Block })[]
}> = ({ value, onChange, sites, onRemove }) => {
  return (
    <div className='border border-accent rounded-b bg-accent bg-opacity-10 min-w-full p-4 flex items-center space-x-2'>
      <label htmlFor=''>Option verlinken mit</label>
      <MaterialSelect
        value={value.siteLinkUuid || ''}
        className='w-96'
        onChange={(e) =>
          onChange({
            siteLinkUuid: e.currentTarget.value,
          })
        }>
        <option value='' disabled>
          Seite wählen
        </option>
        {sites?.map((s) => (
          <option key={s.uuid} value={s.uuid}>
            {s.parent.label} &gt; {s.label}
          </option>
        ))}
      </MaterialSelect>
      <TrashIcon className='clickable' onClick={onRemove} />
    </div>
  )
}
