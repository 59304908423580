import { useQuery } from '@apollo/client'
import { useRouteMatch } from 'react-router-dom'
import { GET_FOLDER } from '../backbone/queries'
import { Folder, QueryFolderArgs } from '../backbone/types'
import { useBreadcrumbs } from './useBreadcrumbs'

export const useSetSubfolderBreadcrumb = () => {
  const match = useRouteMatch<{ uuid: string }>('/folder/:uuid')
  const uuid = match?.params.uuid || ''
  const { setBreadcrumbs } = useBreadcrumbs()

  useQuery<{ folder: Folder }, QueryFolderArgs>(GET_FOLDER, {
    variables: { uuid },
    onCompleted: ({ folder }) =>
      setBreadcrumbs([
        { label: 'Admin-Bereich', path: '/' },
        { label: 'Formulare', path: '/folder' },
        { label: folder.label, path: `/folder/${uuid}` },
      ]),
  })
}
