import React, { FC } from 'react'

interface props {
  error: string | boolean | undefined
}

export const ErrorMessage: FC<props> = ({ error, children }) => (
  <>
    {!!error && (
      <span className='text-red-500 text-sm' data-testid='error-message'>
        {children || error}
      </span>
    )}
  </>
)
