import React, { FC, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { Login } from '../auth/Login'
import { Editor } from '../editor/Editor'
import { RootFolder } from '../folder/RootFolder'
import { SubFolder } from '../folder/SubFolder'
import { SearchResultList } from '../search/SearchResultList'
import { ProtectedRoute } from '../shared/ProtectedRoute'
import { Breadcrumbs } from './Breadcrumbs'
import { SearchFormInput } from './SearchFormInput'

interface props {
  className?: string
}

export const Content: FC<props> = ({ className }) => {
  const [search, setSearch] = useState('')
  return (
    <div className={`${className} ml-[30px] pt-0 pb-16 w-full`}>
      <ProtectedRoute path='/'>
        <div className='flex justify-between items-center h-16'>
          <Breadcrumbs />
          <Route path='/folder'>
            <SearchFormInput setValue={setSearch} value={search} />
          </Route>
        </div>
      </ProtectedRoute>
      <Switch>
        <Route path='/login' component={Login} />
        {/* <Route path='/register' component={Register} /> */}
        {!!search && (
          <ProtectedRoute
            path='/folder'
            render={() => (
              <SearchResultList
                search={search}
                clearSearch={() => setSearch('')}
              />
            )}
          />
        )}
        <ProtectedRoute path='/folder/:uuid' component={SubFolder} />
        <ProtectedRoute path='/folder' component={RootFolder} />
        <ProtectedRoute path='/editor' component={Editor} />
        <Redirect to='/folder' />
      </Switch>
    </div>
  )
}
