import { useCallback, useEffect, useState } from 'react'
import { useEditorState } from './useEditorState'

export const useIsDirty = (delay = 2000) => {
  type Timeout = ReturnType<typeof setTimeout>
  const state = useEditorState()
  const [isDirty, setIsDirty] = useState(false)
  const [timer, setTimer] = useState<Timeout | null>(null)

  const trigger = useCallback(() => {
    if (!!timer) clearTimeout(timer)
    setTimer(
      setTimeout(() => {
        setIsDirty(true)
        setTimer(null)
      }, delay)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsDirty, delay])

  useEffect(trigger, [JSON.stringify(state), trigger])

  return {
    isDirty,
    resolve: () => {
      setIsDirty(false)
      if (timer) clearTimeout(timer)
    },
  }
}
