import { useMutation } from '@apollo/client'
import { DELETE_FORM } from '../backbone/queries'
import { MutationDeleteFormArgs } from '../backbone/types'

export const useDeleteForm = (uuid: string) => {
  const [mutate, request] = useMutation<boolean, MutationDeleteFormArgs>(
    DELETE_FORM,
    { refetchQueries: 'active' }
  )
  return {
    remove: () => mutate({ variables: { uuid } }),
    request,
  }
}
