import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'
import { refresh } from '../redux/auth'
import { store } from '../redux/store'

const uri = process.env.REACT_APP_API_URL || 'http://localhost:3050/graphql/'
const uploadLink = createUploadLink({ uri })
const authLink = new ApolloLink((operation, forward) => {
  const token = store.getState().auth.accessToken
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  }))
  return forward(operation)
})
const refreshTokenLink = onError(
  ({ networkError, graphQLErrors, forward, operation }) => {
    if (networkError) console.warn('[NETWORK ERROR]:', networkError)
    if (graphQLErrors)
      graphQLErrors.forEach(async (err) => {
        console.error('[GRAPHQL ERROR]:', err.message)
        if (err.message === 'Signature has expired') {
          await store.dispatch(refresh())
          await client.query(operation)
          await client.refetchQueries({ include: 'active' })
        }
      })
    else {
      return forward(operation)
    }
  }
)
export const client = new ApolloClient({
  //@ts-ignore
  link: refreshTokenLink.concat(authLink).concat(uploadLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
})
