import React, { FC } from 'react'
import { useEditorBlock } from '../hooks/useEditorBlock'
import { useSnackbar } from '../hooks/useSnackbar'
import { MaterialCheckbox } from '../shared/MaterialCheckbox'

export const BlockTemplateToggle: FC<{ uuid: string }> = ({ uuid }) => {
  const { block, setBlock } = useEditorBlock(uuid)
  const { show: showSnackbar } = useSnackbar({
    title: '\u24D8',
    text: `Der Formularblock ${block.label} wird nun als Vorlage verwendet.`,
  })
  const switchState = () => {
    const newState = !block.isTemplate
    if (newState) showSnackbar()
    setBlock({ isTemplate: newState })
  }

  return (
    <div>
      <MaterialCheckbox checked={!!block.isTemplate} onClick={switchState}>
        Diesen Formularblock als Vorlage speichern
      </MaterialCheckbox>
    </div>
  )
}
