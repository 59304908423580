import React, { FC, KeyboardEvent, useState } from 'react'
import { useUuid } from '../backbone/hooks'
import { useFieldGroups } from '../hooks/useFieldGroups'
import { MaterialInput } from '../shared/MaterialInput'
import { MaterialSelect } from '../shared/MaterialSelect'

interface Props {
  value: string
  onChange: (v: string | undefined) => void
}

export const FieldGroupComboBox: FC<Props> = ({ value, onChange }) => {
  const uuid = useUuid()
  const groups = useFieldGroups()
  const [tempInput, setTempInput] = useState()
  const [isSelecting, setIsSelecting] = useState(true)

  const setValueProxy = (value: string) =>
    value !== uuid ? onChange(value) : setIsSelecting(false)

  const onSubmitTempInput = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setIsSelecting(true)
    }
  }

  return isSelecting ? (
    <MaterialSelect
      value={value || ''}
      className='!max-w-[300px] !w-full !mx-2'
      onChange={(e) => setValueProxy(e.currentTarget.value)}>
      <option value={uuid}>+ Feldgruppe erstellen</option>
      {[tempInput, ...groups]
        .filter((g) => g !== undefined)
        .map((g) => (
          <option key={g} value={g || ''}>
            {g || 'Standard-Gruppe'}
          </option>
        ))}
    </MaterialSelect>
  ) : (
    <MaterialInput
      autoFocus
      className='!max-w-[300px] !w-full !mx-2'
      placeholder='Geben Sie einen Namen ein + Enter'
      onChange={setTempInput as any}
      value={tempInput || ''}
      onKeyUp={onSubmitTempInput}
    />
  )
}
