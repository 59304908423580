import { Block } from '../backbone/types'
import { useEditorActions } from './useEditorActions'
import { useEditorState } from './useEditorState'

export const useEditorBlock = (uuid: string) => {
  const { saveBlock, removeBlock } = useEditorActions()
  const state = useEditorState()
  const block = state.blocks?.find((b) => b.uuid === uuid) || ({} as Block)
  const setBlock = (data: Partial<Block>) =>
    saveBlock({
      ...block,
      ...data,
      uuid,
    } as Block)

  const remove = () => removeBlock(uuid)
  return { block, setBlock, remove }
}
