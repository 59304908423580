import { FC } from 'react'
import { MaterialButton } from '../shared/MaterialButton'
import { useCreateFolder } from './useCreateFolder'

interface props {
  className?: string
}

export const AddFolderButton: FC<props> = (props) => {
  const { createNewFolder } = useCreateFolder()
  return (
    <MaterialButton {...props} onClick={createNewFolder}>
      + Neuen Ordner erstellen
    </MaterialButton>
  )
}
