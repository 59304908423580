import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { authApi } from '../backbone/authApi'
import { Credentials, RegisterPayload } from './auth.types'
import { RootState } from './store'
import { CAN_EDIT_FORMS } from '../backbone/rights'

const wt_decode = (token: string) => {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}

export const login = createAsyncThunk(
  'auth/login',
  async (credentials: Credentials) => (await authApi.login(credentials)).data
)

export const register = createAsyncThunk(
  'auth/register',
  async (payload: RegisterPayload) => (await authApi.register(payload)).data
)

export const refresh = createAsyncThunk(
  'auth/refresh',
  async (_, { getState }) => {
    const state = getState() as RootState
    const refreshToken: string = state.auth.refreshToken
    return (await authApi.refresh(refreshToken)).data
  }
)

const initialState = {
  isLoggedIn: process.env.NODE_ENV === 'development',
  alias: '',
  error: '',
  accessToken: '',
  refreshToken: '',
  registerSucceeded: false,
  rights: [CAN_EDIT_FORMS] as string[],
}
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    toggleLoginStatus: (state) => {
      state.isLoggedIn = !state.isLoggedIn
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(login.rejected, (state) => {
        state.error =
          'Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben'
      })
      .addCase(register.fulfilled, (state) => {
        state.error = ''
        state.registerSucceeded = true
      })
      .addCase(login.fulfilled, (state, action) => {
        const decodedToken = wt_decode(action.payload.accessToken) as any
        state.error = ''
        state.isLoggedIn = true
        state.rights = decodedToken?.permissions
        state.alias = decodedToken?.alias
        state.accessToken = action.payload.accessToken
        state.refreshToken = action.payload.refreshToken
      })
      .addCase(refresh.fulfilled, (state, action) => {
        state.accessToken = action.payload.accessToken
      })
      .addCase(refresh.rejected, (state) => {
        state = {
          ...initialState,
          isLoggedIn: false,
          error:
            'Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein.',
        }
        return state
      }),
})

export const {
  reducer: authReducer,
  actions: { toggleLoginStatus },
} = authSlice
