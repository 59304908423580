import React, { FC } from 'react'
import { useRootFolderItems } from '../backbone/useRootFolderItems'
import { useBreadcrumbs } from '../hooks/useBreadcrumbs'
import { LoadingIndicator } from '../ui/LoadingIndicator'
import { FolderView } from './FolderView'

export const RootFolder: FC = () => {
  const { folders, loading } = useRootFolderItems()
  useBreadcrumbs([
    { label: 'Admin-Bereich', path: '/' },
    { label: 'Formulare', path: '/folder' },
  ])
  return loading ? <LoadingIndicator /> : <FolderView folders={folders} />
}
