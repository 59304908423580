import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { QuestionCircle } from '../icons/QuestionCircle'
import { MaterialButton } from '../shared/MaterialButton'
import { Modal } from '../shared/Modal'
import { helpText } from '../HelpText'

export const EditorHelpButton = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <MaterialButton
        className='!bg-white !text-iconColorDark !shadow-none w-30 flex justify-start items-center mr-6 !py-1'
        onClick={() => setOpen(true)}>
        <QuestionCircle className='fill-current inline-block mt-[1px]' />
        <span className='ml-1 text-lg'>Hilfe</span>
      </MaterialButton>
      <Modal
        title='Hilfe'
        open={open}
        setOpen={setOpen}
        submit={{ action: () => setOpen(false), label: 'Zurück' }}>
        <div className='prose px-4'>
          <ReactMarkdown>{helpText}</ReactMarkdown>
        </div>
      </Modal>
    </>
  )
}
