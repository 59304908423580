import React, { FC } from 'react'
import { useUuid } from '../backbone/hooks'

interface props {
  checked?: boolean
  onClick?: () => void
}
export const MaterialRadioButton: FC<props> = ({
  onClick,
  checked,
  children,
}) => {
  const id = useUuid()
  return (
    <div className='form-check'>
      <label
        className={`htmlForm-check-label block text-dark checked:text-accent ${
          checked && '!text-accent'
        }`}
        htmlFor={id}>
        <input
          onChange={onClick}
          className='htmlForm-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-accent checked:border-accent active:bg-accent active:border-accent accent-accent focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer !ring-0'
          type='radio'
          id={id}
          checked={checked}
        />
        {children}
      </label>
    </div>
  )
}
