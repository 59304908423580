import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useBreadcrumbs } from '../hooks/useBreadcrumbs'
import { BreadcrumbItem } from './BreadcrumbItem'

export const Breadcrumbs: FC = () => {
  const { breadcrumbs } = useBreadcrumbs()
  const defaultRoot = { label: 'Tool', path: '/' }
  const [root = defaultRoot, ...rest] = breadcrumbs
  return (
    <div className='flex'>
      <Link to={root.path}>{root.label}</Link>
      {rest.map((bc, i) => (
        <BreadcrumbItem key={i} {...bc} />
      ))}
    </div>
  )
}
