import { FC, useState } from 'react'
import { SubFolderItemList } from './SubFolderItemList'
import { SubFolderViewHeader } from './SubFolderViewHeader'
import { useSetSubfolderBreadcrumb } from '../hooks/useSetSubfolderBreadcrumb'

export const SubFolder: FC = () => {
  const [filter, setFilter] = useState('')
  useSetSubfolderBreadcrumb()
  return (
    <>
      <SubFolderViewHeader filterProps={{ filter, setFilter }} />
      <hr />
      <SubFolderItemList filter={filter} />
    </>
  )
}
