import { MutationDeleteFolderArgs } from '../backbone/types'
import { useMutation } from '@apollo/client'
import { DELETE_FOLDER } from '../backbone/queries'

export const useDeleteFolder = (uuid: string) => {
  const [mutate, request] = useMutation<boolean, MutationDeleteFolderArgs>(
    DELETE_FOLDER,
    { refetchQueries: 'active' }
  )
  return {
    remove: () => mutate({ variables: { uuid } }),
    request,
  }
}
