import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setBreadcrumbs, State } from '../redux/breadcrumbs'
import { useAppSelector } from '../redux/store'

export const useBreadcrumbs = (initState?: State) => {
  const dispatch = useDispatch()

  const currentState = useAppSelector((state) => state.breadcrumbs)

  const update = useCallback(
    (state: State) => {
      dispatch(setBreadcrumbs(state))
    },
    [dispatch]
  )
  const setInitialState = useCallback(() => {
    if (!!initState) update(initState)
  }, [initState, update])

  // eslint-disable-next-line
  useEffect(setInitialState, [])

  return { breadcrumbs: currentState, setBreadcrumbs: update }
}
