import { useDispatch } from 'react-redux'
import { Block, Form, Site } from '../backbone/types'
import {
  createNewForm,
  loadForm,
  removeBlock,
  removeSite,
  saveBlock,
  saveForm,
  saveSite,
} from '../redux/editor'

export const useEditorActions = () => {
  const dispatch = useDispatch()

  return {
    saveForm: (form: Partial<Form>) => {
      dispatch(saveForm(form))
    },
    loadForm: (form: Form) => {
      dispatch(loadForm(form))
    },
    createNewForm: (folder: string) => {
      dispatch(createNewForm(folder))
    },
    saveBlock: (block: Block) => {
      dispatch(saveBlock(block))
    },
    removeBlock: (uuid: string) => {
      dispatch(removeBlock(uuid))
    },
    saveSite: (blockUuid: string, site: Site) => {
      dispatch(saveSite({ blockUuid, site }))
    },
    removeSite: (uuid: string) => {
      dispatch(removeSite(uuid))
    },
  }
}
