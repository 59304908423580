import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../redux/store'
import { showSnackbar } from '../redux/ui'
import { SnackbarProps } from '../shared/Snackbar'

interface options extends SnackbarProps {
  timeout?: number
}

export const useSnackbar = ({ timeout = 2000, text, title }: options = {}) => {
  const dispatch = useDispatch()

  const state = useAppSelector((state) => state.ui.snackbar)
  const isOpen = !!state.title || !!state.text

  const show = useCallback(() => {
    if (!isOpen) dispatch(showSnackbar({ text, title }))
  }, [text, title, dispatch, isOpen])
  const close = useCallback(() => {
    dispatch(showSnackbar({}))
  }, [dispatch])

  useEffect(() => {
    const timoutId = setTimeout(() => {
      if (isOpen) close()
    }, timeout)
    return () => clearTimeout(timoutId)
  }, [isOpen, close, timeout])

  return {
    state,
    show,
    close,
  }
}
