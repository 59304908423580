import { Select } from '../backbone/types'
import { useAppSelector } from '../redux/store'

export const useEditorSelectFields = () => {
  const selectFields = useAppSelector((s) =>
    s.editor.blocks.flatMap((b) =>
      b.sites
        .flatMap((s) => s.inputs)
        .filter((i) => i.__typename === 'Select')
        .map((i) => ({ ...(i as Select), blockLabel: b.label }))
    )
  )

  return selectFields
}
