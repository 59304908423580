import { Link } from 'react-router-dom'
import { useEditorState } from '../hooks/useEditorState'
import { EditorNavbarListItem } from './EditorNavbarListItem'

export const EditorNavbar = () => {
  const form = useEditorState()
  const label = form?.label || 'Neues Formular'
  return (
    <>
      <li>
        <Link
          to={`/editor/${!!form ? form.uuid : 'new'}`}
          className='font-semibold text-dark mb-4 block'>
          {label}
        </Link>
      </li>
      {form.blocks?.map((block, i) => (
        <EditorNavbarListItem key={i} block={block} indent={false} />
      ))}
    </>
  )
}
