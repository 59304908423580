import React, { FC } from 'react'
import ReactTooltip from 'react-tooltip'
import { Input } from '../backbone/types'
import { FieldTypes } from '../backbone/utils'
import { useSnackbar } from '../hooks/useSnackbar'
import { Arrow } from '../icons/Arrow'
import { InfoIcon } from '../icons/InfoIcon'
import { TrashIcon } from '../icons/TrashIcon'
import { MaterialCheckbox } from '../shared/MaterialCheckbox'
import { MaterialSelect } from '../shared/MaterialSelect'
import { FieldGroupComboBox } from './FieldGroupComboBox'

export const EditorFieldWrapperHeader: FC<{
  input: Input
  reorder: (source: number, destination: number) => void
  isOpen: boolean
  setIsOpen: (v: boolean) => void
  removeInput: () => void
  setGroup: (v: string | undefined) => void
  setIsRequired: (v: boolean) => void
  setColumnWidth: (v: number) => void
  setIsBranchingPoint: (v: boolean) => void
}> = ({
  reorder,
  input,
  isOpen,
  setIsOpen,
  removeInput,
  setIsRequired,
  setColumnWidth,
  setIsBranchingPoint,
  setGroup,
}) => {
  const { position, __typename, label, isRequired } = input
  const title = FieldTypes.find((ft) => ft.type === __typename)?.label || label

  const toggleOpen = () => setIsOpen(!isOpen)
  const moveUp = () => reorder(position, position - 1)
  const moveDown = () => reorder(position, position + 1)
  const { show } = useSnackbar({
    title: <InfoIcon />,
    text: 'Im Pulldown-Menü können keine Verlinkungen verwendet werden!',
  })

  return (
    <div>
      <div className='flex flex-nowrap items-center'>
        <h4 className='font-bold'>{input.label}</h4>
      </div>
    <div className='flex flex-nowrap items-center'>
      <Arrow className='clickable m-1 transform rotate-90' onClick={moveUp} />
      <Arrow
        className='clickable m-1 transform -rotate-90'
        onClick={moveDown}
      />
      <h4 className='mx-2'>{title}</h4>
      <TrashIcon className='clickable m-1' onClick={removeInput} />
      <FieldGroupComboBox onChange={setGroup} value={input.group || ''} />
      {input.__typename === 'TextField' && (
        <FieldColumnWidthSelector
          onChange={setColumnWidth}
          value={input.columns || 6}
        />
      )}
      {input.__typename === 'Select' && (
        <MaterialCheckbox
          onClick={() => {
            if (input.isBranchingPoint) show()
            setIsBranchingPoint(!input.isBranchingPoint)
          }}
          checked={!input.isBranchingPoint}
          className='clickable m-1'>
          Als Pulldown anzeigen
        </MaterialCheckbox>
      )}
      {isOpen && (
        <MaterialCheckbox
          checked={!!isRequired}
          onClick={() => setIsRequired(!isRequired)}
          className='clickable m-1'>
          Pflichtfeld
        </MaterialCheckbox>
      )}
      <Arrow
        className={`clickable m-1 ml-auto transform ${
          isOpen ? 'rotate-90' : '-rotate-90'
        }`}
        onClick={toggleOpen}
      />
    </div>
    </div>
  )
}

export const FieldColumnWidthSelector = ({
  onChange,
  value,
}: {
  value: number
  onChange: (v: number) => void
}) => {
  const columnWidths = [1, 2, 3, 4, 5, 6]
  return (
    <>
      <MaterialSelect
        data-tip='Hier können Sie die Breite des Feldes einstellen um komplexere Eingaben wie bspw. Adressfelder zu realisieren.'
        className='w-16'
        value={value || 6}
        onChange={(e) => onChange(parseInt(e.currentTarget.value))}>
        {columnWidths.map((c) => (
          <option key={c} value={c}>
            {c}
          </option>
        ))}
      </MaterialSelect>
      <ReactTooltip />
    </>
  )
}
